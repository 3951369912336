import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import IBPWhiteLogo from "../../../assets/media/icons/IBP-white.png";
import useRequest from "hooks/useRequest";
import { AuthService } from "../../../services/AuthService";
import Alert from "../../atoms/Alert";
import { isMobile } from "helpers/Mobile";

import "./styles.scss";
import ActionButton from "components/atoms/ActionButton";
import { makeStyles, Grid, TextField, Button, Box } from "@material-ui/core";
import { Height, PersonRounded, LockRounded } from "@material-ui/icons";
export function Logout() {
  const history = useHistory();

  useEffect(() => {
    AuthService.logout();
    history.push("/");
  }, []);

  return <div></div>;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minHeight: "100vh",
    backgroundImage: `url(assets/background-login.webp)`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    "&:before": {
      content: '""',
      position: "absolute",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
      backgroundColor: "#8888895c",
    },
  },
}));

const useStylesForm = makeStyles((theme) => ({
  root: {
    backgroundColor: "#00000080",
    minHeight: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  form: {
    display: "flex",
    width: "54%",
    Height: "600px",
    flexDirection: "column",
    alignItems: "center",
    padding: "40px",
  },
  buttonContained: {
    color: "white",
    boxShadow: "none",
    backgroundColor: "#44546a",
    width: "50%",
    marginTop: "80px",
  },
  ImgTitle: {
    width: "100%",
    marginBottom: "80px",
  },
  inputText: {
    "& .MuiFilledInput-root": {
      background: "white",
    },
  },
}));

const FormLogin = ({ onSubmit, setUsername, setPassword }) => {
  const classes = useStylesForm();

  return (
    <div className={"login " + classes.root}>
      <form className={classes.form} onSubmit={onSubmit}>
        <img src={IBPWhiteLogo} className={classes.ImgTitle} />
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            width: "100%",
            position: "relative",
          }}
        >
          <PersonRounded
            style={{ fill: "white", position: "absolute", bottom: "5%", left: -50 }}
            fontSize="large"
          />
          <TextField
            id="outlined-basic"
            variant="filled"
            placeholder={"Usuario"}
            name="email"
            size="small"
            type="email"
            onChange={(e) => setUsername(e.target.value)}
            className={classes.inputText}
            fullWidth
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            width: "100%",
            position: "relative",
          }}
        >
          <LockRounded
            style={{ fill: "white", position: "absolute", bottom: "5%", left: -50 }}
            fontSize="large"
          />
          <TextField
            id="outlined-basic"
            variant="filled"
            placeholder={"Contraseña"}
            className={classes.inputText}
            name="password"
            size="small"
            type="password"
            style={{ marginTop: "40px" }}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
          />
        </Box>
        <Button
          type="submit"
          variant="contained"
          classes={{
            contained: classes.buttonContained,
          }}
        >
          Login
        </Button>
      </form>
    </div>
  );
};

function Login({ t, userData }) {
  const classes = useStyles();
  const history = useHistory();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState(false);
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    type: "error",
    title: "Login Credentials",
  });

  const handleHideAlert = () => {
    setAlert({ ...alert, show: false });
  };

  const hadleLogin = (e) => {
    e.preventDefault();
    AuthService.login(username, password).then((response) => {
      if (response?.token) {
        setToken(response);
      } else {
        setAlert({
          show: true,
          type: "error",
          message: "Email o contraseña invalida",
        });
      }
    });
  };

  React.useEffect(() => {
    const token= AuthService.getToken();
    if(token){
      history.push("/dashboard");
    }
  }, [token]);

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={6} style={{ backgroundColor: "rgba(0,0,0,0.9)" }}>
          <FormLogin
            onSubmit={hadleLogin}
            setUsername={setUsername}
            setPassword={setPassword}
          />
        </Grid>
        <Grid item xs={6} style={{ backgroundColor: "rgba(0,0,0,0.5 )" }} />
      </Grid>
      <Alert {...alert} setShow={handleHideAlert} />
    </div>
  );
}

export default withTranslation()(Login);
