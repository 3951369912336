import React from "react";
import "./styles.scss";

const SubHeaderDetails = ({ isEdit, initialData, onChange }) => {
  const inputs = [
    {
      name: "email",
      type: "email",
      label: "Email",
      disabled: isEdit,
      required: true,
      style: {
        width: "225px",
      },
    },
    {
      name: "first_name",
      type: "text",
      label: "Nombre:",
      disabled: false,
      required: false,
    },
    {
      name: "last_name",
      type: "text",
      label: "Apellido:",
      disabled: false,
      required: false,
    },
    {
      name: "age",
      type: "number",
      label: "Edad:",
      disabled: false,
      required: false,
    },
    {
      name: "occupation",
      type: "text",
      label: "Profesion:",
      disabled: false,
      required: false,
    },
  ];
  const [data, setData] = React.useState(initialData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  React.useEffect(() => {
    onChange(data);
  }, [data]);

  React.useEffect(() => {
    setData(initialData);
  }, [initialData]);
  return (
    <div className="sub-heading__container d-flex w-100">
      <div className="sub-heading__content d-flex">
        {inputs.map((input, index) => (
          <div>
            <label htmlFor={input.name}>{input.label}</label>
            <input
              type={input?.type}
              name={input?.name}
              value={data ? data[input?.name] : ""}
              onChange={handleChange}
              disabled={input?.disabled}
              required={input?.required}
              style={input?.style || {}}
              className="sub-heading__input"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default React.memo(SubHeaderDetails);
