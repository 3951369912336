import './styles.scss';
import React, { useEffect } from 'react'

export default function Alert({show, setShow, message, type, title }) {
    
    useEffect(() => {
        setTimeout(() => {
            setShow(false);
        }, 5000)
    }, [show])

    if (show) {
        return (
            <div className={"alert alert--" + type} >
                { title ? <h4 className='alert-title'>{title}</h4> : <> </> }
                <p>
                    { message }
                </p>
            </div>
        )
    }

    return (
        <></>
    )
}
