import React, { useState, useEffect, useRef } from "react";
import { withTranslation } from "react-i18next";
import "./styles.scss";
import { AuthService } from "services/AuthService";
import Api from "services/ApiService";
import Layout from "components/organisms/Layout";
import Table from "components/molecules/Table";
import Buscador from "components/molecules/Buscador";
import BarChart from "components/molecules/BarChart";
import PieChart from "components/molecules/ChartPie";
import Environment from "environment";
import { Redirect } from "react-router-dom";
import ListMeetSummary from "components/molecules/ListMeetSummary";
import Title from "components/molecules/Title";
import ListVideoTeca from "components/molecules/ListVideoTeca";
import {IBP, IFA, WMI} from "models/User";

function AdminDashboard() {
  const [page, setPage] = useState(1);
  const [teamsTabla, setTeamsTabla] = useState([]);
  const [count, setCount] = useState(0);
  const PAGELIMIT = 10;
  const [tablaFiltrada, setTablaFiltrada] = useState([]);
  const [selected, setSelected] = useState();

  const [pacs, setPacs] = useState([])
  const [pdis, setPdis] = useState([])
  const [categories, setCategories] = useState([])
  
  const [teamsPie, setTeamsPie] = useState([])
  
  const cabeceras = [
    { label: "Código por equipo", field: "code" },
    { label: "Nivel jerárquico", field: "level" },
    { label: "Cantidad de asesores", field: "salesperson_count" },
    { label: "Acciones", field: "action" },
  ];
  const idUser = AuthService.user().id;
  const teamId = AuthService.user().team_id;
  const salespersonId = AuthService.user().salesperson_id;

  const countTeams = Math.ceil(count / PAGELIMIT);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [pacsMetrics, setPacsMetrics] = useState(0);
  const [pdisMetrics, setPdisMetrics] = useState(0);
  const [ratioMetrics, setRatioMetrics] = useState(0);
  const [metricsVideos, setMetricsVideos] = useState([]);

  const text = "Por equipo"

  const [select, setSelect] = useState(1);
  const [teams, setTeams] = useState([]);
  const [isLeaf, setIsLeaf] = useState();
  const [whoseTotal, setWhoseTotal] = useState();

  useEffect(() => {
      setCount(tablaFiltrada.length);
  }, [tablaFiltrada])

  useEffect(() => {
    Api.fetch(`${Environment.api}api/v2/users/${idUser}/teams/`, "GET")
      .then((data) => {
        setTeamsTabla(data);
      })
      .catch((error) => console.log(error));
  }, [idUser]);

  useEffect(() => {
    Api.fetch(`${Environment.api}api/v2/metrics/video/`, "GET")
      .then((data) => {
        setMetricsVideos(data);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    const filter = teamsTabla.map((el) => {return {level: el.level, code: el.code}}).filter((value, index, self) =>
                        index === self.findIndex((t) => (
                          t.level === value.level
                        ))
                      )
    setTeams(filter)
  }, [teamsTabla])
  
  useEffect(() => {
      if (!teams.length) return
    let queryParams = {
      ...(startDate) && {from_date: startDate.toISOString().substring(0,19)},
      ...(endDate) && {to_date: endDate.toISOString().substring(0,19)},
    };

    let restsParams = {
      ...(startDate) && {from_date: startDate.toISOString().substring(0,10)},
      ...(endDate) && {to_date: endDate.toISOString().substring(0,10)},
    };

    let urlWithParams = Api.getUrlWithParams(`${Environment.api}metrics/interviews`, queryParams);
    
    //AuthService.get(urlWithParams.pathname.replace(/\//,'')+''+urlWithParams.search, (r, data) => setMetrics(data));

    Api.fetch(`${Environment.api}api/v2/metrics/teams/${teamId}`, 'GET', restsParams )
      .then(data => {
        setPacs(data.pacs);
        setPdis(data.pdis);
        setCategories(data.categories);
      })
      .catch((error) => console.log(error)); 
 

    Api.fetch(`${Environment.api}api/v2/metrics/teams/${teamId}/children/`, 'GET', restsParams )
      .then(data => {
        let dataMapped = data ? data.map((elem) => {
          return {
            name: elem.code,
            y: Math.round(elem.ratio),
            pacs: Math.round(elem.pacs),
            pdis: Math.round(elem.pdis),
            selected: elem.id == teamId ? true : false,
            id: elem.id,
            level: elem.level
          }
        }).filter((elem) => { return (elem.y !== 0 && (select !== '' ? elem.level === parseInt(select) : true) ) } ) : []
        
        setIsLeaf(teams[teams.length - 1]?.level == select)
        
        setTeamsPie(dataMapped)

        let ratioMetrics = dataMapped.reduce((previousValue, currentValue) => previousValue + currentValue.y,
            0);
        
        let pacsMetrics = dataMapped.reduce((previousValue, currentValue) => previousValue + currentValue.pacs,
            0);

        let pdisMetrics = dataMapped.reduce((previousValue, currentValue) => previousValue + currentValue.pdis,
            0);

        setPdisMetrics(pdisMetrics)
        setPacsMetrics(pacsMetrics)
        //setRatioMetrics(ratioMetrics > 0 ? ratioMetrics / dataMapped.length : 0)
        setSelected(teamId);
        setRatioMetrics(Math.round(pdisMetrics * 100 / pacsMetrics))
      })
      .catch((error) => console.log(error));

  }, [endDate, startDate, select, teamId, teams]);

  const handleClick = (selectedTeam) => {
    let restsParams = {
      ...(startDate) && {from_date: startDate.toISOString().substring(0,10)},
      ...(endDate) && {to_date: endDate.toISOString().substring(0,10)},
    };
    Api.fetch(`${Environment.api}api/v2/metrics/teams/${selectedTeam.id}`, 'GET', restsParams )
      .then(data => {
        setWhoseTotal(data.code)
        setPacs(data.pacs);
        setPdis(data.pdis);
        setCategories(data.categories);

        let pacsMetrics = data.pacs.reduce((previousValue, currentValue) => previousValue + currentValue,
            0);

        let pdisMetrics = data.pdis.reduce((previousValue, currentValue) => previousValue + currentValue,
            0);

        let ratioMetrics = data.ratio.reduce((previousValue, currentValue) => previousValue + currentValue,
            0);

        setPdisMetrics(pdisMetrics)
        setPacsMetrics(pacsMetrics)

        //setRatioMetrics(ratioMetrics > 0 ? ratioMetrics / data.ratio.length : 0)
        setRatioMetrics(Math.round(pdisMetrics * 100 / pacsMetrics))
      })
      .catch((error) => console.log(error));
  }

  const isLogged = localStorage.getItem("access");

  const chartComponent = useRef({});

  useEffect(() => {
    const chart = chartComponent.current?.chart;

    if (chart) chart.reflow(false);
  }, [isLeaf]);

  if (!isLogged) return <Redirect to="/" />;

  // Si el usuario no es IBP solo mostrar la pagina de su equipo o la de asesor
  if (AuthService.user().team_type === WMI) return <Redirect to={`/dashboard/equipos/${teamId}`} />;
  if (AuthService.user().team_type === IFA) return <Redirect to={`/dashboard/asesores/${teamId}`} />;

  return (
    <Layout backgroundColor="#dedede" style={{ marginLeft: 30 }}>
      <div className="">
        <div className="ms-4 d-flex">
          <div className="mt-3 d-block">
            <h2 className="mt-4 ms-3">Equipos</h2>
            <div className="">
              <Buscador
                setTablaFiltrada={setTablaFiltrada}
                tablaFiltrada={tablaFiltrada}
                teamsTabla={teamsTabla}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                select={select}
                setSelect={setSelect}
              />
            </div>
          </div>
        </div>
        <div className="d-flex   graficos">
          <div className="mt-2">
            <div className="ms-5">
              <div>
                <Title title={"Estadisticas de los equipos"} />
              </div>
            </div>
            <div className="metricas ms-2">
              <ListMeetSummary pdisMetrics={pdisMetrics} pacsMetrics={pacsMetrics} ratioMetrics={ratioMetrics} />
            </div>
            <h3 className="d-flex mt-5 mb-5 ms-5">Incidencias por equipo</h3>
            <div className="highcharts mt-3 mb-3 ms-3">
                  <BarChart title={whoseTotal} chartComponent={chartComponent} className="card-video" pacs={pacs} pdis={pdis} categories={categories}/>
                  {AuthService.user().team_type !== IFA && <PieChart className="card-video" data={teamsPie} text={text} select={selected} setSelected={setSelected} handleClick={handleClick}/>}
            </div>
          </div>
          <div className="list-videos mt-2">
            <Title title={"Recursos"} />
            <div className="lista-videos">
              <ListVideoTeca metricsVideos={metricsVideos}/>
            </div>
          </div>
        </div>
        {tablaFiltrada && (
          <Table
            cabeceras={cabeceras}
            setTablaFiltrada={setTablaFiltrada}
            tablaFiltrada={tablaFiltrada}
            setPage={setPage}
            countTeams={countTeams}
            page={page}
            pageLimit={PAGELIMIT}
          />
        )}
      </div>
      <div className="separadorDashboard" />
      <div className="separadorDashboard" />
    </Layout>
  );
}
export default withTranslation()(AdminDashboard);
