import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";

import Paginacion2 from "../Paginacion2";

import "./styles.scss";
import Paginacion from "../Paginacion";

const TableAsesores = ({ cantAsesores, id, code, users }) => {
  const cantPagina = 10;
  const [pagina, setPagina] = useState(1);
  const [pageStart, setPageStart] = useState(0);
  return (
    <>
    <div className="box-asesores">
      <div className="table-asesores">
        <table className="">
          <thead>
            <tr>
              <th className="header-table">Código</th>
              <th className="header-table">Nombre</th>
            </tr>
          </thead>
          <tbody>
            {users?.slice(pageStart, pageStart + cantPagina).map((user, i) => {
              return (
                  <tr key={i + 1 * 5}>
                    <td>
                      <NavLink to={`/dashboard/asesores/${user.id}?detalle=true`}>
                        {user.level_name}
                      </NavLink>
                    </td>
                    <td>
                      <NavLink to={`/dashboard/asesores/${user.id}?detalle=true`}>
                        {user.full_name}
                      </NavLink>
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </div>
    </div>
    <Paginacion2 setPagina={(value) => {
      setPageStart((value - 1) * cantPagina);
    }
    } cantAsesores={cantAsesores} cantPagina={cantPagina} />
    </>
  );
};

export default TableAsesores;
