import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { rem } from "polished";


const useStyleCardVideoTitle = makeStyles(() => ({
    root: {
      textAlign: "center",
      marginBottom: rem("30px"),
    },
    title: {
      fontWeight: "bold",
      color: "#272727",
      letterSpacing: "3px",
      marginLeft: rem("10px"),
    },
  }));

const CardVideoTitle = ({ title }) => {
    const classes = useStyleCardVideoTitle();
    return (
        <Grid container className={classes.root} alignItems="center">
          <Grid item xs={11} container justifyContent="flex-start">
            <Typography className={classes.title} variant="h6">
              {title}
            </Typography>
          </Grid>
        </Grid>
    );
  };

  export default CardVideoTitle;