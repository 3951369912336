import React, { useEffect, useState } from "react";
import { AuthService } from "services/AuthService";
import "./styles.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
//refactor
import cn from "classnames";
import {
  List,
  ListItem,
  ListItemText,
  Drawer,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {
  HomeSharp,
  ComputerSharp,
  AssignmentSharp,
  PowerSettingsNewSharp,
  OndemandVideoSharp,
  SchoolSharp,
  ArrowBackSharp
} from "@material-ui/icons";

import PeopleAltIcon from '@material-ui/icons/PeopleAlt';

import IbpLogo from "assets/media/icons/logo-mini-ibp.png";
import { useInterviewTemp } from "contexts/interviewTemp.context";


const useStyles = makeStyles(() => ({
  toolbarSeparator: {
    minHeight: "48px",
  },
  drawer: {
    width: "150px",
    flexShrink: 0,
    boxShadow: "1px 2px 6px rgba(0, 0, 0, 0.2)",
    "& .MuiPaper-root": {
      position: "relative",
      width: "150px",
      backgroundColor: "#262626",
      overflowX: "hidden",
      whiteSpace: "nowrap",
      borderRight: "none",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      paddingTop: "24px"
    },
  },
  listItem: {
    height: "72px",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    padding: 0,
    madginBottom: "5%",
    paddingLeft: 20,
    "&:hover": {
      backgroundColor: "#2c4560",
    },
    color: "white",
  },
  activeListItem: {
    backgroundColor: "#ffffff2e",
  },
  itemText: {
    "& .MuiTypography-body1": {
      fontSize: "11px",
    },
  },
  icon: {
    height: "35px",
    width: "35px",
    color: "#d6d6d6",
    paddingRight: 10,
  },
  footerItem: {
    bottom: 0,
    position: "absolute",
    width: "100%",
  },
}));

const Footer = () => {
  const classes = useStyles();
  const history = useHistory();

  const label = "Salir";
  const Icon = PowerSettingsNewSharp;

  const handleLogout = () => {
    history.push("/logout");
  };

  return (
    <ListItem
      className={cn(classes.footerItem, classes.listItem)}
      button
      key={label}
      title={label}
      onClick={handleLogout}
    >
      <Icon className={classes.icon} />
      <ListItemText
        primary={label.toUpperCase()}
        className={classes.itemText}
      />
    </ListItem>
  );
};

const useStyleUserInfo = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    top: 30,
  },
  text: {
    color: "white",
    fontWeight: "normal",
    marginTop: 10,
  },
}));

const UserInfo = React.memo(() => {
  const classes = useStyleUserInfo();
  const userName = AuthService.user().salesperson_first_name;
  const lastName = AuthService.user().salesperson_last_name;
  const teamName = AuthService.user().team_name;
  const userId = String(AuthService.user().id).padStart(3, '0');
  return (
    <div className={classes.root}>
      <img src={IbpLogo} alt="logo" width={70} />

      <Typography
        variant="subtitle1"
        className={classes.text}
        style={{ fontSize: "11px" }}
        >
        {`${userName}   ${lastName}`}
      </Typography>
      {teamName != 'null' && <Typography
        variant="subtitle1"
        className={classes.text}
        style={{ fontSize: "11px" }}
        >
        {`${teamName} - ${userId}`}
      </Typography>}
    </div>
  );
});



const Sidebar = () => {
  const history = useHistory();
  const classes = useStyles();
  const { resumeInterviewUrl } = useInterviewTemp();

  const isActive = (path) => {
    return history.location.pathname === path;
  };

  const handleRedirect = (redirect) => {
    history.push(redirect);
  };

  const sideBar = [
    {
      label: "Home",
      Icon: HomeSharp,
      redirect: "/dashboard",
    },
    // {
    //   label: "Equipos",
    //   Icon: PeopleAltIcon,
    //   redirect: "/dashboard/equipos",
      
    // },
    {
      label: "E-Book",
      Icon: ComputerSharp,
      redirect: "/pac",
    },
    {
      label: "Reuniones",
      Icon: AssignmentSharp,
      redirect: "/reuniones",
    },
    {
      label: "Volver",
      Icon: ArrowBackSharp,
      redirect: null,
      disabled: !resumeInterviewUrl,
      onClick: () => { history.push(resumeInterviewUrl); }
    },
    {
      label: "VideoTeca",
      Icon: OndemandVideoSharp,
      redirect: "/videoteca",
    },
    {
      label: "E-Learning",
      Icon: SchoolSharp,
      redirect: "/videos/digital_school",
    },
    {
      label: null,
      ImgIcon: () => (
        <img
          src="/assets/logo_ibpedia.png"
          alt="logo"
          width={"70%"}
          height={"70%"}
        />
      ),
      redirect: "/videos/ibpedia",
    },
  ];

 const onlyAdmin = {
  label: "Equipos",
  Icon: PeopleAltIcon,
  redirect: "/dashboard/equipos",
  
}


  if(AuthService.user().team_id  != 'null' && AuthService.user().team_id  != 'undefined'){
    sideBar.splice(1, 0, onlyAdmin);//posicion de perfiles
  }

  return (
    <Drawer className={classes.drawer} variant="permanent">
      <UserInfo />
      <List style={{ width: "100%" }}>
      {sideBar.map(({ Icon, ImgIcon, label, redirect, onClick, disabled }, index) => (
              <ListItem
                className={cn(
                  classes.listItem,
                  isActive(redirect) && classes.activeListItem
                )}
                disabled={disabled}
                button
                key={label || index}
                title={label}
                onClick={() => redirect ? handleRedirect(redirect) : onClick()}
              >
                {Icon ? <Icon className={classes.icon} /> : <ImgIcon />}
                <ListItemText
                  primary={label?.toUpperCase()}
                  className={classes.itemText}
                />
              </ListItem>
            
      ))
        }
      </List>
      <Footer />
    </Drawer>
  );
};

export default React.memo(Sidebar);
