import Loading from "components/atoms/Loading";
import React from "react";
import { withTranslation } from "react-i18next";
import PACPDIContainer from "../../organisms/PACPDIContainer";
import { AuthService } from "../../../services/AuthService";
import { useHistory } from "react-router-dom";
import { useModalVideo } from "contexts/modalVideo.context";
import { getSrcVideoBlop } from "services/videos";

const VideoInfo = (props) => {
  const user_id = props.user_id;
  const id = props.id;
  const [isVisibile, setIsVisibile] = React.useState(false);
  const { open, setVideoSrc, setIsClose } = useModalVideo();
  setIsClose(false);
  const history = useHistory();

  const currentUserId = AuthService.user().id;

  React.useEffect(() => {
    AuthService.get(`videos/${user_id}/info/${id}`, async (r, data) => {
      const { is_private } = data?.video || {};
      const { user_allowed } = data || false;
      if (!user_allowed) {
        history.push("/");
        return;
      }
      
      if (is_private) {
        if (currentUserId === null)  {
          history.push("/");
          return;
        }
      }
      const src = await getSrcVideoBlop(user_id, id);
      setVideoSrc(src, user_id, id);
      setIsVisibile(true);
      open();
    });
  }, []);

  if (!isVisibile) {
    return <Loading />;
  }

  return (
    <PACPDIContainer
      hideNav={true}
      backgroundSrc={"/assets/background-videoteca.webp"}
    ></PACPDIContainer>
  );
};

export default withTranslation()(VideoInfo);
