import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import useRequest from 'hooks/useRequest';

import { isMobile } from 'helpers/Mobile';

import './styles.scss';
import ActionButton from 'components/atoms/ActionButton';

const Home = (props) => {
    const {
        beforeSubmit, afterSubmit, errors, message,
        showSuccess, setMessage, 
        dealWithError
    } = useRequest();

    const history = useHistory();

    return <h1>{props.title}</h1>
}

export default withTranslation()(Home);