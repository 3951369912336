import React, { useState, useEffect, useRef } from "react";
import { withTranslation } from "react-i18next";
import "./styles.scss";
import PACPDIContainer from "../../organisms/PACPDIContainer";
import PACPDIHeader from "../../atoms/PACPDIHeader";
import SubHeaderDetails from "../../atoms/SubHeadingDetails";
import PDITable from "../../atoms/PDITable";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Environment from "../../../environment";
import { AuthService } from "../../../services/AuthService";
import { convertArrayToObject } from "./../../../utils/Array";
import { ObjectIsEmpty } from "./../../../utils/Objects";
import IconButton from "@material-ui/core/IconButton";
import { useModalVideo } from "contexts/modalVideo.context";
import { getSrcVideoBlop } from "services/videos";

import PlayCircleFilledWhiteOutlinedIcon from "@material-ui/icons/PlayCircleFilledWhiteOutlined";
import { useInterviewTemp } from "contexts/interviewTemp.context";



const TitleContainer = ({ children }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        position: "relative",
        height: 60,
      }}
    >
      {children}
    </div>
  );
};

const ButtonPlayIcons = ({ videoId }) => {
  const { open, setVideoSrc } = useModalVideo();
  const handleClick = async () => {
    open();
    const userId = AuthService.user().id;
    const src = await getSrcVideoBlop(userId, videoId);

    setVideoSrc(src, userId, videoId);
  };

  return (
    <IconButton
      color="primary"
      aria-label="upload picture"
      component="span"
      onClick={handleClick}
      style={{ padding: 0 }}
    >
      <PlayCircleFilledWhiteOutlinedIcon
        style={{
          fontSize: 60,
          fill: "white",
          marginRight: "6.5rem",
        }}
      />
    </IconButton>
  );
};

const PillasMultipleSelection = ({
  idealInvestorRef,
  t,
  onChange,
  initValue,
}) => {
  const [idealInvestor, setIdealInvestor] = useState([]);

  const data = [
    { label: "Seguridad", value: "security", videoId: 15 },
    { label: "transparencia", value: "transparency", videoId: 16 },
    { label: "flexibilidad", value: "flexibility", videoId: 17 },
    { label: "rentabilidad", value: "profitability", videoId: 18 },
    { label: "diversificacion", value: "diversification", videoId: 19 },
    { label: "Liquidez", value: "liquidity", videoId: 20 },
  ];

  const isChecked = (value) => idealInvestor?.includes(value);

  //set value onChange
  React.useEffect(() => {
    const keys = data?.map((item) => item.value);
    const itemNotChecked = convertArrayToObject(
      keys.filter((item) => !isChecked(item)),
      false
    );
    const itemChecked = convertArrayToObject(idealInvestor);
    const value = { ...itemChecked, ...itemNotChecked };

    onChange(value);
  }, [idealInvestor]);

  //Init value
  React.useEffect(() => {
    if (initValue === undefined) return;
    const defaultValue = Object?.keys(initValue || {})?.filter(
      (key) => initValue[key] === true
    );
    setIdealInvestor(defaultValue);
  }, [initValue]);

  const { open: openVideoModal, setVideoSrc } = useModalVideo();
  const handleOpenVideo = async (videoId) => {

    openVideoModal();
    const userId = AuthService.user().id;
    const src = await getSrcVideoBlop(userId, videoId);
    setVideoSrc(src, userId, videoId);
  };

  return (
    <div class="pac__grid-item pac__money-cycle pillars">
      <TitleContainer>
        <h5>{t("6 pilares de una inversion ideal")}</h5>
        <ButtonPlayIcons videoId={14} />
      </TitleContainer>
      <ul ref={idealInvestorRef}>
        {data.map((item, index) => {
          return (
            <li key={index}>
              <a
                href="#0"
                onClick={() => {
                  handleOpenVideo(item.videoId);
                  return false;
                }}
              >
                {item.label}{" "}
              </a>
              <div>
                <input
                  name={item.value}
                  type="radio"
                  id={item.value}
                  value={item.value}
                  checked={isChecked(item?.value)}
                  onClick={(e) => {
                    if (!isChecked(item.value)) {
                      setIdealInvestor([...idealInvestor, item?.value]);
                    } else {
                      setIdealInvestor(
                        idealInvestor.filter((i) => i !== item?.value)
                      );
                    }
                  }}
                ></input>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const EcomForm = ({ defaultValue, onChange }) => {
  const [checkedValue, setChecked] = React.useState([]);
  const data = [
    { label: "AHORRISTA", value: "saver" },
    {
      label: "INVERSOR",
      value: "investor",
    },
    { label: "RENTISTA", value: "rentier" },
  ];

  const isChecked = (value) => checkedValue?.includes(value);

  const handleOnChange = (value) => {
    const MAX_SELECTED = 2;

    if (isChecked(value)) {
      setChecked(checkedValue.filter((i) => i !== value));
    } else if (checkedValue?.length < MAX_SELECTED) {
      setChecked((prevState = []) => [...prevState, value]);

    }
  };

  //init value
  React.useEffect(() => {
    if (defaultValue === undefined) return;
    const initValues = Object?.keys(defaultValue || {})?.filter(
      (key) => defaultValue[key] === true
    );
    setChecked(initValues);
  }, [defaultValue]);

  React.useEffect(() => {

    const keys = data?.map((item) => item.value);
    const itemNotChecked = convertArrayToObject(
      keys.filter((item) => !isChecked(item)),
      false
    );
    const itemChecked = convertArrayToObject(checkedValue);
    const value = { ...itemChecked, ...itemNotChecked };
    onChange(value);
  }, [checkedValue]);

  return (
    <>
      <div class="pac__grid-item pac__money-cycle">
        <TitleContainer>
          <h5>{"Ciclo Evolutivo del Dinero"}</h5>
          <ButtonPlayIcons videoId={12} />
        </TitleContainer>
        <ul>
          {data?.map((item, index) => (
            <li key={index}>
              {item?.label}
              <div>
                <input
                  name={item.value}
                  id={item.value}
                  value={item.value}
                  checked={isChecked(item?.value)}
                  type="radio"
                  onClick={() => handleOnChange(item?.value)}
                />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

const InvestmentProfile = ({ defaultValue, onChange }) => {
  const [checkedValue, setChecked] = React.useState("");
  const data = [
    { label: "CAUTELOSO", value: "cautious" },
    { label: "BALANCEADO", value: "balanced" },
    { label: "AGRESIVO", value: "aggressive" },
    { label: "REBALANCEADO", value: "rebalanced" },
  ];

  React.useEffect(() => {
    setChecked(defaultValue);
  }, [defaultValue]);

  React.useEffect(() => {
    onChange(checkedValue);
  }, [checkedValue]);

  return (
    <>
      <div class="pac__grid-item pac__money-cycle">
        <TitleContainer>
          <h5>{"Perfil del Inversor"}</h5>
          <ButtonPlayIcons videoId={13} />
        </TitleContainer>

        <ul>
          {data?.map((item, index) => (
            <li key={index}>
              {item?.label}
              <div>
                <input
                  checked={checkedValue === item?.value}
                  type="radio"
                  name="profile"
                  onChange={(e) => setChecked(item?.value)}
                ></input>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

const ObservationsForms = ({
  defaultValueComment,
  defaultValueNextdate,
  onChange,
}) => {
  const [comment, setComment] = React.useState("");
  const [nextdate, setNextdate] = React.useState("");

  React.useEffect(() => {
    onChange("comments", comment);
  }, [comment]);

  React.useEffect(() => {
    onChange("next_date", nextdate);
  }, [nextdate]);

  React.useEffect(() => {
    if (defaultValueComment === undefined) {
      return;
    }
    setComment(defaultValueComment);
  }, [defaultValueComment]);

  React.useEffect(() => {
    if (defaultValueNextdate === undefined) {
      return;
    }
    const defaultNextDate = defaultValueNextdate?.split("T")[0];
    setNextdate(defaultNextDate);
  }, [defaultValueNextdate]);

  return (
    <>
      <div class="pac__grid-item pac__money-cycle bordered">
        <label>
          <span>Observaciones:</span>
          <textarea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          ></textarea>
        </label>

        <label>
          <span>Fecha de 2° entrevista:</span>
          <input
            type="date"
            value={nextdate}
            onChange={(e) => setNextdate(e.target.value)}
          />
        </label>
      </div>
    </>
  );
};

const PAC = (props) => {
  const { t } = props;
  const history = useHistory();
  const { stateTmp, setStateTmp, setResumeInterViewUrl } = useInterviewTemp();
  // refs
  const formRef = useRef(null);
  const idealInvestorRef = useRef(null);

  const url = new URL(window.location.href);
  const updateId = url.searchParams.get("id");
  const isEdit = Boolean(updateId);

  const keySaveTmp = isEdit ? `update_pac_${updateId}` : "save_pac";

  const tmpValues = { ...stateTmp?.[keySaveTmp] };
  const [interview, setInterview] = useState({
    ...tmpValues,
  });
  const [values, setValues] = useState({
    salesperson_id: AuthService.getId(),
    ...tmpValues,
  });

  React.useEffect(() => {
    const { pathname, search } = history?.location || {};
    if (isEdit) setResumeInterViewUrl(`${pathname}${search}`);
    else setResumeInterViewUrl(null);

    if (updateId) {

      AuthService.get("interviews/" + updateId, (r, data) => {
        const { investment_funds, ...allData } = data;

        setInterview({ ...allData, ...tmpValues });
      });
    }
  }, []);

  const onSuccess = (r) => {
    //reset
    setResumeInterViewUrl(null);
    setStateTmp({});
    history.push({
      pathname: "/success",
      search: "?next=reuniones",
    });
  };

  const handleSubmit = (type, success, url = "interviews") => {
    document.getElementById("pac-pdi-form").reportValidity();
    if (isEdit) {
      url = `${url}/${updateId}`;
      type = "put";
    }

    const body = {
      ...values,
      salesperson_id: AuthService.getId(),
    };
    AuthService[type](url, body, (r) => success(r));
  };

  React.useEffect(() => {
    const tmpForm = {
      [keySaveTmp]: values,
    };
    setStateTmp(tmpForm);
  }, [values]);

  const handleChange = (name, value) => {
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };


  return (
    <PACPDIContainer
      t={t}
      updateId={updateId}
      ref={formRef}
      submit={() => handleSubmit("post", onSuccess)}
      next="/reuniones"
      hideGoBackPac={true}
      hideGoBackPid={!updateId}
      backgroundSrc="/assets/background-pac.webp"
    >
      <PACPDIHeader
        header={t("PAC")}
        fullHeader={t("Protocolo de apertura de cuentas")}
        iconVideoId={39}
      />
      <SubHeaderDetails
        initialData={interview?.client}
        onChange={(value) => handleChange("client", value)}
        isEdit={isEdit}
      />
      <div class="pac__grid-container">
        <EcomForm
          defaultValue={interview?.ecom}
          onChange={(value) => handleChange("ecom", value)}
        />
        <InvestmentProfile
          defaultValue={interview?.investment_profile ?? ''}
          onChange={(value) => handleChange("investment_profile", value)}
        />
        <PillasMultipleSelection
          idealInvestorRef={idealInvestorRef}
          t={t}
          initValue={interview?.pillars} //@todo fix re-renders
          onChange={(value) => handleChange("pillars", value)}
        />
        <ObservationsForms
          defaultValueNextdate={interview?.next_date}
          defaultValueComment={interview?.comments}
          onChange={(name, value) => handleChange(name, value)}
        />
      </div>
    </PACPDIContainer>
  );
};

export default withTranslation()(PAC);
