import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import useRequest from 'hooks/useRequest';

import { isMobile } from 'helpers/Mobile';

import PAC from 'components/templates/PAC';

const PACEditView = (props) => {
    const {
        beforeSubmit, afterSubmit, errors, message,
        showSuccess, setMessage, 
        dealWithError
    } = useRequest();

    const history = useHistory();

    const url = new URL(window.location.href);

    return <PAC updateId={url.searchParams.get('id')}></PAC>
}

export default withTranslation()(PACEditView);