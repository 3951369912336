import React, { useState } from "react";
import "./styles.scss";
import IfonIcon from "../../../assets/media/icons/white-info.svg";
import {
  Typography,
  TextField,
} from "@material-ui/core";
import { convertArrayToObject } from "./../../../utils/Array";
import { useModalVideo } from "contexts/modalVideo.context";
import { getSrcVideoBlop } from "services/videos";
import { AuthService } from "services/AuthService";

const RowItem = React.memo(({ item, isError, defaultValue, handleChange, handleOpenInfo, handleOpenVideo,handleVisibilityChange, }) => {
  return (
    <tr >
  
      <td>
        <h4
          style={{cursor: "pointer" }}
          onClick={() => {
            handleOpenVideo(item?.urlBloombergVideoId);
          }}
          >
           Bloomberg
         </h4>
                      
        <h4>
          <a
            style={{ textDecoration: "none", fontSize: 14 }}
            target="_blank"
            rel="noreferrer"
            href={item.urlBloomberg}
          >
            {item.label}
          </a>
        </h4>
        {isError && (
          <span
          style={{
              color: "red",
              fontWeight: "bold",
              width: "100%",
              textAlign: "end",
            }}
          >
            Mínimo 10 %
          </span>
        )}
      </td>
      <td className="pdi-table__percetage">
        <InputTextField
          defaultValue={defaultValue}
          handleChange={handleChange}
          isError={isError}
          name={item.name}
          />
      </td>
      <td
        className="pdi-table__info"
        onClick={() => {
          handleOpenInfo(item.urlPdf);
        }}
      >
        <img src={IfonIcon} />
      </td>
      
     <td onClick={() => handleVisibilityChange(item.name, !item.isVisible)}  className={"pdi-table__info"}>
       <HideVideoButton/>
     </td>
     
      <td
        className="pdi-table__info video"
        onClick={() => {
          handleOpenVideo(item?.urlVideoId);
        }}
        ></td>
        
    </tr>
  );
})

const HideVideoButton = () => {
  return (
    <svg width="23px" height="23px" stroke-width="3" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 3l18 18M10.5 10.677a2 2 0 002.823 2.823" stroke="#ffffff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path>
    <path d="M7.362 7.561C5.68 8.74 4.279 10.42 3 12c1.889 2.991 5.282 6 9 6 1.55 0 3.043-.523 4.395-1.35M12 6c4.008 0 6.701 3.158 9 6a15.66 15.66 0 01-1.078 1.5" stroke="#ffffff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" cursor="pointer"></path>
  </svg>
  );
}

const userId = AuthService.user().id;

function InputTextField({ defaultValue, handleChange, isError, name }) {
  const [value, setValue] = React.useState(null);
  
  React.useEffect(() => {
    handleChange(value, name);
  }, [value]);
  
  React.useEffect(() => {
    if (defaultValue === 0) {
      return;
    }
    setValue(defaultValue);
  }, [defaultValue]);
  
  return (
    <TextField
      variant="filled"
      type="number"
      size="small"
      style={{ padding: 0 }}
      name="percentage"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      inputProps={{
        min: 10,
        max: 100,
      }}
      error={isError}
      />
      );
    }
    
    const PDITable = (props) => {
      const { open: openVideoModal, setVideoSrc } = useModalVideo();
      const { investment_funds, isCompleted, status } = props;
      const url = new URL(window.location.href);
      const broker_url = 'https://mps.pacificam.co.uk/performance'
      
      const data = [
        {
          label: "DCS Global Bonds",
          name: "dcs_global_bonds",
          value: investment_funds["dcs_global_bonds"],
          star: false,
          urlPdf: "https://api.dominion-cs.com/al/assets/funds/2/factsheet/2",
          urlBloomberg: broker_url,
          urlVideoId: "22",
          urlBloombergVideoId: "184",
        },
        {
          label: "DCS Global Equities",
          name: "dcs_global_equities",
          value: investment_funds.dcs_global_equities,
          star: false,
          urlPdf: "https://api.dominion-cs.com/al/assets/funds/1/factsheet/2",
          urlBloomberg: broker_url,
          urlVideoId: "21",
          urlBloombergVideoId: "185",
        },
        {
          label: "DCS Cautious",
      name: "dcs_cautious",
      value: investment_funds.dcs_cautious,
      star: false,
      urlPdf: "https://api.dominion-cs.com/al/assets/funds/3/factsheet/2",
      urlBloomberg: broker_url,
      urlVideoId: "23",
      urlBloombergVideoId: "181",
    },
    { 
      label: "DCS Balanced",
      name: "dcs_balanced",
      value: investment_funds.dcs_balanced,
      star: false,
      urlPdf:
      "https://api.dominion-cs.com/al/assets/funds/4/factsheet/2",
      urlBloomberg: broker_url,
      urlVideoId: "34",
      urlBloombergVideoId: "179",
    },
    {
      label: "DCS Aggressive",
      name: "dcs_aggresive",
      value: investment_funds.dcs_aggresive,
      star: false,
      urlPdf: "https://api.dominion-cs.com/al/assets/funds/5/factsheet/2",
      urlBloomberg: broker_url,
      urlVideoId: "24",
      urlBloombergVideoId: "178",
    },
    {
      label: "DCS Emerging markets",
      name: "dcs_emerging_markets",
      value: investment_funds.dcs_emerging_markets,
      star: false,
      urlPdf: "https://api.dominion-cs.com/al/assets/funds/6/factsheet/2",
      urlBloomberg: broker_url,
      urlVideoId: "25",
      urlBloombergVideoId: "183",
    },
    {
      label: "DCS New Technologies",
      name: "dcs_new_technologies",
      value: investment_funds.dcs_new_technologies,
      star: true,
      urlPdf: "https://api.dominion-cs.com/al/assets/funds/7/factsheet/2",
      urlBloomberg: broker_url,
      urlVideoId: "26",
      urlBloombergVideoId: "188",
    },
    {
      label: "DCS Sustainable Growth",
      name: "dcs_sustainable_growth",
      value: investment_funds.dcs_sustainable_growth,
      star: true,
      urlPdf:
      "https://api.dominion-cs.com/al/assets/funds/8/factsheet/2",
      urlBloomberg: broker_url,
      urlVideoId: "27",
      urlBloombergVideoId: "189",
    },
    {
      label: "DCS E-Commerce",
      name: "dgt_e-commerce",
      value: investment_funds["dgt_e-commerce"],
      star: true,
      urlPdf:
      "https://api.dominion-cs.com/al/assets/funds/14/factsheet/2",
      urlBloomberg: broker_url,
      urlVideoId: "28",
      urlBloombergVideoId: "182",
    },
    {
      label: "DCS Luxury",
      name: "dgt_luxury",
      value: investment_funds.dgt_luxury,
      star: true,
      urlPdf:
      "https://api.dominion-cs.com/al/assets/funds/15/factsheet/2",
      urlBloomberg: broker_url,
      urlVideoId: "29",
      urlBloombergVideoId: "186",
    },
    {
      label: "DCS Managed",
      name: "dgt_managed",
      value: investment_funds.dgt_managed,
      star: true,
      urlPdf:
      "https://api.dominion-cs.com/al/assets/funds/16/factsheet/2",
      urlBloomberg: broker_url,
      urlVideoId: "30",
      urlBloombergVideoId: "187",
    },
    ////////////////////////////////// NEW
    {
      label: "DCS G10 MACRO RATES FUND",
      name: "dcs_g10_macro_rates_fund",
      value: investment_funds.dcs_g10_macro_rates_fund,
      star: false,
      urlPdf:
        "https://api.dominion-cs.com/al/assets/funds/26/factsheet/2",
        urlBloomberg: broker_url,
        urlVideoId: "429",
        urlBloombergVideoId: "430",
      },
      ////////////////////////////////// NEW 2
      
      {
        label: "DCS MULTI ASSET SUSTAINABLE BALANCED",
        name: "dcs_multi_asset_sustainable_balanced",
        value: investment_funds.dcs_multi_asset_sustainable_balanced,
        star: false,
        urlPdf:
        "https://api.dominion-cs.com/al/assets/funds/27/factsheet/2",
        urlBloomberg: broker_url,
        urlVideoId: "431",
        urlBloombergVideoId: "432",
      },
      ////////////////////////////////// NEW 3
      
      {
        label: "DCS LONGEVITY AND SOCIAL CHANGE FUND",
        name: "dcs_longevity_and_social_change_fund",
        value: investment_funds.dgt_managed,
        star: false,
        urlPdf:
        "https://api.dominion-cs.com/al/assets/funds/25/factsheet/2",
      urlBloomberg: broker_url,
      urlVideoId: "433",
      urlBloombergVideoId: "434",
    },
    ////////////////////////////////// NEW 4
    
    {
      label: "DCS SP500 TRACKER FUND",
      name: "dcs_sp500_tracker_fund",
      value: investment_funds.dcs_sp500_tracker_fund,
      star: false,
      urlPdf:
      "https://api.dominion-cs.com/al/assets/funds/28/factsheet/2",
      urlBloomberg: broker_url,
      urlVideoId: "435",
      urlBloombergVideoId: "436",
    },
    ////////////////////////////////// NEW 5
    
    {
      label: "DCS GLOBAL GROWTH",
      name: "dcs_global_growth",
      value: investment_funds.dcs_global_growth,
      star: false,
      urlPdf:
      "https://api.dominion-cs.com/al/assets/funds/8/factsheet/2",
      urlBloomberg: broker_url,
      urlVideoId: "437",
      urlBloombergVideoId: "438",
    },
  ];
  
  const footerData = [
    {
      label: "DCS Cash",
      name: "dcs_cash",
      value: investment_funds.dcs_cash,
      star: false,
      urlPdf: "https://api.dominion-cs.com/al/assets/funds/10/factsheet/2",
      urlBloomberg: broker_url,
      urlVideoId: "32",
      urlBloombergVideoId: "180",
    },
    {
      label: "DCS Opportunities Plus",
      name: "dcs_opportunities_plus",
      value: props.investment_funds["dcs_opportunities_plu"],
      star: false,
      urlPdf:
      "https://api.dominion-cs.com/al/assets/funds/21/factsheet/2",
      urlBloomberg: broker_url,
      urlVideoId: "33",
      urlBloombergVideoId: "184",
    },
  ];
  
  const [visibleData, setVisibleData] = useState(data);
  const [visibleFooterData, setVisibleFooterData] = useState(footerData);
  const { percentage, setPercentage } = props.percentage;
  const [sum, setSum] = useState(0);
  const minValue = 10;
  
  const handleChange = (e, name) => {
    const value = parseFloat(e) || 0;
    setPercentage((cs) => {
      return { ...cs, [name]: value };
    });
  };
  
  const verifySum = (percentage) => {
    let totalSum = 0;
    if (!percentage) return totalSum;
    Object.keys(percentage).map((key) => {
      totalSum += Number(percentage[key]);
    });
    
    return totalSum;
  };
  
  const handleOpenVideo = async (videoId) => {
    openVideoModal();
    const src = await getSrcVideoBlop(AuthService.user().id, videoId);
    setVideoSrc(src, userId, videoId);
  };
  
  const handleOpenInfo = async (url) => {
    window.open(url, "_blank").focus();
  };
  
  props.verify(sum);
  
  const handleVisibilityChange = (name, isVisible) => {
    // Actualiza la visibilidad del objeto en el array y en el localStorage
    const updatedData = visibleData.map((item) => {
      if (item.name === name) {
        localStorage.setItem("visibility", JSON.stringify({ ...JSON.parse(localStorage.getItem("visibility") || "{}"), [name]: isVisible }));
        return { ...item, isVisible };
      }
      return item;
    });

    setVisibleData(updatedData);
  };

  const handleFooterVisibilityChange = (name, isVisible) => {
    // Actualiza la visibilidad del objeto en el array y en el localStorage
    const updatedData = visibleFooterData.map((item) => {
      if (item.name === name) {
        localStorage.setItem("visibility", JSON.stringify({ ...JSON.parse(localStorage.getItem("visibility") || "{}"), [name]: isVisible }));
        return { ...item, isVisible };
      }
      return item;
    });

    setVisibleFooterData(updatedData);
  };

  const handleResetClick = (e) => {
    // para que no se recargue la página.
    e.preventDefault()
    // Restablece la visibilidad de todos los objetos en el array y en el localStorage
    localStorage.removeItem("visibility");
    const updatedData = visibleData.map((item) => {
      return { ...item, isVisible: true };
    });
    const updatedFooterData = visibleFooterData.map((item) => {
      return { ...item, isVisible: true };
    });
    setVisibleData(updatedData);
    setVisibleFooterData(updatedFooterData)
  };
  
  //initial
  React.useEffect(() => {
    const params = [...data, ...footerData];
    setPercentage(
      convertArrayToObject(
        params.map((item) => item.name),
        0
        )
        );
      }, []);
  
  React.useEffect(() => {
        // Obtiene la visibilidad guardada en el localStorage para cada objeto
    const savedVisibility = JSON.parse(localStorage.getItem("visibility")) || {};
    
    // Actualiza la visibilidad de cada objeto en el array
    const updatedData = data.map((item) => {
      return {
        ...item,
        isVisible: savedVisibility[item.name] !== false, // Si no hay un valor guardado en localStorage, se asume que el objeto es visible
      };
    });
    
const updatedFooterData = footerData.map((item) => {
      return {
        ...item,
        isVisible: savedVisibility[item.name] !== false, // Si no hay un valor guardado en localStorage, se asume que el objeto es visible
      };
    });
    
    setVisibleData(updatedData);
    setVisibleFooterData(updatedFooterData);
    }, []);
  
  
      //change %
      React.useEffect(() => {
        const sum = verifySum(percentage);
        setSum(sum);
      }, [percentage]);
      
      //update
      React.useEffect(() => {
        if (!isCompleted) return;
        setPercentage({ ...investment_funds });
      }, [isCompleted]);
      
      return (
        <div class="d-flex flex-column table-container">
      <button onClick={(e)=>handleResetClick(e)} className="showall">Mostrar todos</button>
      <table className="pdi-table">
        <thead>
          <tr>
            <th
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              Estrategias De Fondos
            </th>
            <th class="pdi-table__percetage">
              <Typography variant="h6">{sum}%</Typography>
            </th>
            <th class="pdi-table__info"></th>
            <th class="pdi-table__info"></th>
            <th class="pdi-table__info"></th>
          </tr>
        </thead>

        <tbody>
          {visibleData?.map((item, index) => {
            const isError =
              percentage?.[item.name] !== 0 &&
              percentage?.[item.name] < minValue;
            const defaultValue = investment_funds[item.name];
            //if (!isCompleted || !defaultValue) return null;
            if(item.isVisible){
              return (
                <RowItem key={index} {...{ item, isError, defaultValue, handleChange, handleOpenInfo, handleOpenVideo,handleResetClick,handleVisibilityChange }} />
                );
              }
              return 
            })}
        </tbody>
      </table>
      {
        // Si no quieren mostrar los videos de la tabla de abajo, no se ve la tabla.

      visibleFooterData[0].isVisible || visibleFooterData[1].isVisible ? 
      <table className="pdi-table">
        <thead>
          <tr>
            <th class="pdi-table__header-active">
              PIP - PROTECTED INVESTMENT PORTFOLIO
            </th>
            <th class="pdi-table__percetage"></th>
            <th class="pdi-table__info"></th>
            <th class="pdi-table__info"></th>

            <th
              className="pdi-table__info video"
              onClick={() => {
                handleOpenVideo(31);
              }}
            />
          </tr>
        </thead>

        <tbody>
          {visibleFooterData.map((item, index) => {
            if(item.isVisible){
              return (
                <tr key={index}>
                  <td>
                     <h4
                      style={{cursor: "pointer" }}
                      onClick={() => {
                        handleOpenVideo(item?.urlBloombergVideoId);
                      }}
                      >Bloomberg</h4>
                      <h4>

                      <a
                        style={{ textDecoration: "none", fontSize: 14 }}
                        target="_blank"
                        rel="noreferrer"
                        href={item.urlBloomberg}
                      >
                        {item.label}
                      </a>
                    </h4>
                  </td>
                  <td className="pdi-table__percetage pdi-table__percetage">
                    <input
                      type="number"
                      name="percentage"
                      defaultValue={investment_funds[item.name]}
                      onChange={(e) => handleChange(e.target.value, item.name)}
                    />
                  </td>
                  <td
                    className="pdi-table__info"
                    onClick={() => {
                      handleOpenInfo(item.urlPdf);
                    }}
                  >
                    <img src={IfonIcon} />
                  </td>
  
                  <td onClick={() => handleFooterVisibilityChange(item.name, !item.isVisible)}  className={"pdi-table__info"}>
                <HideVideoButton/>
                  </td>
  
  
                  <td
                    className="pdi-table__info video"
                    onClick={() => {
                      handleOpenVideo(item?.urlVideoId);
                    }}
                  />
                </tr>
              );
            }
            else return
          })}
        </tbody>
      </table> : <></>
      }
    </div>
  );
};

export default React.memo(PDITable);
