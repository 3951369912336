import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        margin: 10,
        width: "100%",  
        display: "flex",        
        justifyContent: "flex-start",
        alignItems: "center",

    },
    pagination: {
        "& .MuiPagination-ul > li > button": {
            color:"#ffffffa8",
            borderColor:"#ffffffa8",
        },
        '& .Mui-selected': {
            backgroundColor: '#595959',
            color:'#19D5C6',
           },
    }
}))
export default useStyles;