import React from "react";
import { Grid, makeStyles } from "@material-ui/core";

const useStyleCardVideoItem = makeStyles(() => ({
  text: {
    color: "#a3a3a3",
    fontWeight: "normal",
    letterSpacing: "3px",
  },
}));

const CardVideoItem = ({ id, label }) => {
  const classes = useStyleCardVideoItem();
  return (
    <>
      <Grid item xs={12}>
        <span className={classes.text}>
          {id}. {label}
        </span>
      </Grid>
    </>
  );
};
export default CardVideoItem;
