import React from "react";
import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import cn from "classnames";
import CountUp from "react-countup";

const useStylesCardSummary = makeStyles(() => ({
  root: ({ backgroundColor }) => ({
    backgroundColor: backgroundColor,
    padding: 20,
    paddingRight: 40,
    paddingLeft: 40,
    borderRadius: 30,
    width: "30%",
    margin: 10,
  }),
  countUp: {
    fontSize: 64,
  },
  textColor: {
    color: "white",
  },
  bold: {
    fontWeight: "bold",
  },
}));

const animationDuration = 1.5;

const CardMeetSummary = ({
  title,
  description,
  quantity,
  backgroundColor,
  prefix,
}) => {
  const classes = useStylesCardSummary({
    backgroundColor,
  });
  return (
    <Paper elevation={6} className={"dashboard " + classes.root}>
      <div className="row">
        <Typography
          className={cn(classes.textColor, classes.bold)}
          variant="h3"
          style={{ fontSize: "1rem" }}
        >
          {String(title).toUpperCase()}
        </Typography>
        <CountUp
          className={cn(classes.countUp, classes.textColor, classes.bold)}
          start={0}
          end={quantity}
          duration={animationDuration}
          useEasing={true}
          useGrouping={true}
          decimals={0}
          style={{ fontSize: "2rem", marginTop: "12px", marginBottom: "9px" }}
          suffix={prefix}
        />
        <Grid item xs={12}>
          <Typography className={classes.textColor} variant="subtitle1">
            {description}
          </Typography>
        </Grid>
      </div>
    </Paper>
  );
};

export default CardMeetSummary;
