import React, { useState, useEffect } from "react";
import {
  BrowserRouter,
  Switch,
  Router,
  Route,
  Redirect,
} from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Provider, ErrorBoundary} from '@rollbar/react'

import "./App.css";

import { Context, ContextPersist } from "./store/context";
import { AuthService } from "./services/AuthService";

import HomeView from "./views/Home/HomeView";
import VideoView from "./views/Videoteca/VideoView";
import SchoolVideoView from "./views/Videoteca/SchoolVideoView";
import IBPediaVideoView from "./views/Videoteca/IBPediaVideoView";
import PDIView from "views/PDI/PDIView";
import PACView from "views/PAC/PACView";
import SuccessView from "views/Success/SuccessView";
import Login from "components/templates/Login";
import { Logout } from "components/templates/Login";
import DashboardView from "views/Dashboard/DashboardView";
import AdminDashboard from './components/templates/Admin/'
import MeetingsView from "views/Meetings/MeetingsView";
import PACEditView from "views/PACEdit/PACEditView";
import ProfileView from "views/Profile/ProfileView";
import VideoInfoView from "views/VideoInfo/VideoInfoView";
import { ModalVideoProvider } from "contexts/modalVideo.context";
import { InterviewTempProvider } from "contexts/interviewTemp.context";
import Equipo from "components/templates/Equipo";
import Asesor from "components/templates/Asesor";

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
      captureUncaught: true,
      captureUnhandledRejections: true
}

function ProtectRouters({ children }) {
  const history = useHistory();

  React.useEffect(() => {
    const token = AuthService.getToken();
    if (!token) history.push("/");
  }, [])

  return (<>{children}</>)
}

function App() {
  const history = useHistory();

  const [user, setUser] = useState(null);


  return (
    <Provider config={rollbarConfig}>
    <ErrorBoundary>
      <ContextPersist>
        <InterviewTempProvider>
          <ModalVideoProvider>
            <Context>
              <main>
                <Switch>
                  <React.Fragment>
                      {/* { (load && !user) &&
                          <div>
                              <Route path='/login' component={SignInView} />
                          </div>
                          }

                          { (load && user) && */}
                    <Route
                      exact
                      path="/"
                      component={() => (
                        <Login userData={{ user: user, setUser: setUser }} />
                      )}
                    />
                      <Route exact path="/videoteca" component={VideoView} />
                      <Route exact path="/pdi" component={PDIView} />
                      <Route exact path="/pac" component={PACView} />
                      <Route exact path="/logout" component={Logout} />
                      <Route exact path="/success" component={SuccessView} />
                      <Route exact path="/dashboard" component={DashboardView} />
                      <Route exact path="/dashboard/equipos" component={AdminDashboard} />
                      <Route exact path="/dashboard/equipos/:nroEquipo" component={Equipo} />
                     {/*  <Route exact path="/dashboard/equipos/:nroEquipo/:idUser/:idAsesor" component={Asesor} /> */}
                      <Route exact path="/dashboard/asesores/:nroEquipo" component={Equipo} />
                      <Route exact path="/reuniones" component={MeetingsView} />
                      <Route exact path="/pac/edit" component={PACEditView} />
                      <Route exact path={"/profile"} component={ProfileView} />
                      <Route exact path={"/videos/digital_school"} component={SchoolVideoView} />
                      <Route exact path={"/videos/ibpedia"} component={IBPediaVideoView} />
                    <Route exact path={"/video/:user_id/info/:id"} component={VideoInfoView} />
                    {/* <Route exact path="/" component={HomeView}/> */}
                    {/* } */}
                  </React.Fragment>
                </Switch>
              </main>
            </Context>
          </ModalVideoProvider>
        </InterviewTempProvider>
      </ContextPersist>
    </ErrorBoundary>
    </Provider>
  );
}

export default App;
