import React ,{ useState } from 'react';
import DatePicker from 'react-datepicker';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
 
import './styles.scss';
import 'react-datepicker/dist/react-datepicker.css';

export const DateRanges = ({ startDate, setStartDate, endDate , setEndDate}) => {
    
    return (
      <div className='d-flex align-items-center'>
        <h6 className=' pe-3 ps-3 text-dateRange'>Desde:</h6>
        <div className='search1'>
            <ExpandMoreIcon className='arrow'/>
            <DatePicker
              placeholderText="Todos"
              selected={startDate}
              onChange={(date) => {console.log(date); setStartDate(date)}}
              selectsStart
              endDate={endDate}
            />
        </div>
        <h6 className='pe-3 ps-3 text-dateRange'>Hasta:</h6>
        <div className='search1'>
            <ExpandMoreIcon className='arrow'/>
            <DatePicker
              placeholderText="Todos"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              disabled={startDate ? false : true}
            />
        </div>
      </div>
    );
  };