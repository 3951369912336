import React from "react";
import Modal from "components/atoms/Modal";
import { Spinner } from "react-bootstrap";
import { Fab, Snackbar } from "@material-ui/core";
import { Share } from "@material-ui/icons";
import { AuthService } from "services/AuthService";
import Api from "services/ApiService";
import Environment from "environment";

function SharedVideo({ userId, src }) {
  const [open, setOpen] = React.useState(false);
  const handleOnClick = async () => {
    const userCurrentId = AuthService.user();
    try {
      let videoUrl = "";

      // Agrego esta opción temporalmente: si el usuario no está logeado, no puede generar link para compartir
      // Lo que debería hacer es tomar el valor de la URL actual!
      if (userCurrentId.id === null) {
        videoUrl = `${window.location}`;
      }
      else {
        videoUrl = `${window.location.origin}/video/${userId}/info/${src}`;
      }
      console.log("VideoURL: " + videoUrl);
      await navigator.clipboard.writeText(videoUrl);
      Api.fetch(`${Environment.api}api/v2/metrics/video/`, "POST", {
        "tracked_item": src,
        "action_type": "SHA"
      })
        .then((data) => {
          console.log('-->Loggin video view');
          console.log(data);
        })
        .catch((error) => console.log(error));
      setOpen(true);
    } catch (e) {}
  };
  
  return (
    <>
      <Fab
        color="primary"
        aria-label="shared"
        onClick={handleOnClick}
        style={{
          position: "absolute",
          top: "50%",
          marginLeft: 20,
          backgroundColor: "#d4af37",
        }}
      >
        <Share />
      </Fab>
      <Snackbar
        anchorOrigin={{ vertical: "centero", horizontal: "bottom" }}
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={6000}
        message="Video Copiado"
        key={"bottomcenter"}
      />
    </>
  );
  

}

function ModalVideo({ isOpen, closeModal, videoSrc, videoRef, videoId }) {
  const userId = AuthService.user().id;
  const videoIsLoaded = videoSrc === "";
  React.useEffect(() => {
    if (videoIsLoaded) {
      return;
    }
    if (isOpen) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, [videoSrc]);
  return (
    <Modal isOpen={isOpen} close={closeModal}>
      {videoIsLoaded ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
          }}
        >
          <Spinner animation="border" variant="light" />
          <span style={{ color: "white" }}>Cargando Video...</span>
        </div>
      ) : (
        <>
          <video
            style={{ width: "60vw" }}
            ref={videoRef}
            controls
            controlsList="nodownload"
            src={videoSrc}
            onContextMenu={(e)=> e.preventDefault()}
            />
          <SharedVideo userId={userId} src={videoId} />
        </>
      )}
    </Modal>
  );
}

const VideoModalContext = React.createContext(null);
const initialState = {
  isOpen: false,
  videoSrc: "",
  userId: "",
  videoId: "",
};
export function ModalVideoProvider({ children }) {
  const [state, setState] = React.useState(initialState);
  const [isClose, setIsClose] = React.useState(true);
  const video = React.useRef(null);

  const open = () => setState((prev) => ({ ...prev, isOpen: true }));
  const close = () => {
    if (isClose) {
      setState(initialState);
    }
  };
  const setVideoSrc = (src, userId, videoId) => {
    setState((prev) => ({ ...prev, videoSrc: src, userId, videoId }));
    Api.fetch(`${Environment.api}api/v2/metrics/video/`, "POST", {
        "tracked_item": videoId,
        "action_type": "VIE"
      })
    .then((data) => {
      console.log('-->Loggin video view');
      console.log(data);
    })
    .catch((error) => console.log(error));
  };

  return (
    <>
      <VideoModalContext.Provider value={{ open, setVideoSrc, setIsClose }}>
        {children}
      </VideoModalContext.Provider>
      <ModalVideo closeModal={close} {...{ ...state }} videoRef={video} />
    </>
  );
}
export function useModalVideo() {
  const context = React.useContext(VideoModalContext);
  if (context === undefined) {
    throw new Error("useVideoModal must be used within a VideoModalProvider");
  }
  return context;
}
