import React, { useState, useEffect } from "react";
import VideoCategory from "components/atoms/VideoCategory";
import VideoButton from "components/atoms/VideoButton";
import Loading from "components/atoms/Loading";
import { AuthService } from "../../../services/AuthService";
import { useModalVideo } from "contexts/modalVideo.context";
import { getSrcVideoBlop } from "services/videos";

import "./styles.scss";

const userId = AuthService.user().id;

const VideoCategoryContainer = (props) => {
  const { open, setVideoSrc } = useModalVideo();
  const [videosData, setVideosData] = useState(null);
  
  useEffect(() => {
    AuthService.get("videos?type=" + props.type, (r, data) => setVideosData(data));
  }, []);

  const handleOpenPopUp = async (videoId) => {
    open();

    const src = await getSrcVideoBlop(AuthService.user().id, videoId);
    setVideoSrc(src, AuthService.user().id, videoId);
  };

  return (
    <div>
      {videosData ? (
        Object.keys(videosData.videos).map((categoryName, index) => (
          <div className="category__container" key={index}>
            <VideoCategory category={categoryName} />
            <div className="videobuttons__container">
              {videosData?.videos[categoryName].map((video, index) => (
                <VideoButton
                  key={index}
                  handleOpenPopUp={() => {
                    handleOpenPopUp(video.id);
                  }}
                  label={video.name}
                />
              ))}
            </div>
          </div>
        ))
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default VideoCategoryContainer;
