import React from "react";

const InterViewTempContext = React.createContext(null);

export function InterviewTempProvider({ children }) {
  const [stateTmp, setStateTmpData] = React.useState({});
  const [resumeInterviewUrl, setResumeInterViewUrl] = React.useState(null);

  const setStateTmp = (data) => {
    setStateTmpData((prev) => ({ ...prev, ...data }));
  };
  return (
    <InterViewTempContext.Provider
      value={{
        stateTmp,
        setStateTmp,
        resumeInterviewUrl,
        setResumeInterViewUrl,
      }}
    >
      {children}
    </InterViewTempContext.Provider>
  );
}

export function useInterviewTemp() {
  const context = React.useContext(InterViewTempContext);
  if (context === undefined) {
    throw new Error(
      "useInterviewTemp must be used within a InterviewTempContextProvider"
    );
  }
  return context;
}
