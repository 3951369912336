import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../molecules/Footer";
import Layout from "components/organisms/Layout";
import IBPWhiteLogo from "../../../assets/media/icons/IBP-white.png";

import "./styles.scss";
import { useHistory } from "react-router-dom";

const PACPDIContainer = ({
  children,
  t,
  submit,
  hideFooter,
  hideNav,
  updateId,
  hideGoBackPac = true,
  hideGoBackPid = true,
  backgroundSrc = null,
}) => {
  const history = useHistory();

  const handleSubmitForm = (e) => {
    e.preventDefault();
    submit();
    // history.push('/success');
  };

  return (
    <Layout backgroundImageSrc={backgroundSrc} hiddeSideBar={hideNav}>
      <form id="pac-pdi-form" className="pac-pdi__container">
        <div className="pac-pdi__content">
          <img className="dominion-icon" src={IBPWhiteLogo} />
          {children}

          <Footer>
            
            <div className="videoteca__footer-actions">

            {updateId || submit ? (
                 <Link
                 to={{ pathname: "https://app.dominion-cs.com/es/login" }}
                 target="_blank" 
                 rel="noopener noreferrer"
                 className="dominion-button"
               ></Link>
              ): null}

              {!hideGoBackPac && updateId && (
                <>
                <Link
                    to={updateId ? "/pac?id=" + updateId : "/pac"}
                    className="circle back"
                  ></Link>
                  <span>{t("Volver a PAC")}</span>
                </>
                
              )}
              {!hideGoBackPid && updateId && (
                <>
                  <Link
                    to={updateId ? "/pdi?id=" + updateId : "/pdi"}
                    className="circle next"
                  ></Link>
                  <span>{t("Ir a PDI")}</span>
                </>
              )}

              {submit ? (
                <div className="save__container">
                  <button class="save-form" onClick={handleSubmitForm}>
                    Guardar
                  </button>
                </div>
              ) : null}
            </div>
            
          </Footer>
        </div>
      </form>
    </Layout>
  );
};

export default PACPDIContainer;
