import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function Paginacion2({ cantAsesores, cantPagina, setPagina }) {
  const countTeams = Math.ceil(cantAsesores / cantPagina);

  const handleChange = (event, value) => {
    setPagina(value);
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Pagination
        count={countTeams}
        variant="outlined"
        shape="rounded"
        onChange={handleChange}
      />
    </div>
  );
}
