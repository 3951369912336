import React from "react";

import Highcharts from "highcharts";
import PieChart from "highcharts-react-official";
import "./styles.scss";

const ChartPie = ({className, data, text, selected, setSelected, handleClick}) => {

const options = {
  title: {
    text: text
  },
  exporting: {
    enabled: false
  },
  credits: {
    enabled: false
  },
  legend: {
    enabled: false
  },
  tooltip: {
    enabled: false
  },
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: 0,
    plotShadow: false
  },
  plotOptions: {
    pie: {
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        color: "rgba(0,0,0,0.87)",
        style: {
          fontWeight: "normal",
          textOutline: "none"
        },
        connectorWidth: 0,
        distance: 10,
        formatter() {
          return `${this.y}%<br> ${this.point.name}`;
        }
      },
      point:{
          events: {
            click: (e) => {
                setSelected(e.point.y);
                console.log(e.point,"e.point");
                handleClick(e.point)
            }

      }
    }
    }
  },
  series: [
    {
      type: "pie",
      innerSize: "60%",
      data: data
      }
    ]
  };
  
    //const traigo = options.series[0].data.find((dat)=> dat.y == select)
  return (
      <PieChart className={className} highcharts={Highcharts} options={options}  />
  )
}

export default ChartPie;