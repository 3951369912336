import React from "react";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import "./styles.scss";

const BarChart = ({title = '', chartComponent, className, pacs, pdis, categories}) => {
  return (
        <HighchartsReact
          highcharts={Highcharts}
          className={className}
          ref={chartComponent}
          options={{
            exporting: {
              enabled: false
            },
            credits: {
              enabled: false
            },
            legend: {
              enabled: true
            },
            tooltip: {
              enabled: true
            },
            chart: {
                type: 'column'
            },
            title: {
              text: "Total "+title
            },
            series: [
              {
                data: pacs,
                name: 'PACS',
                type: 'column',
                color: '#5b9bd5',
              },
              {
                data: pdis,
                name: 'PDIS',
                type: 'column',
                color: '#a9d18e',
              }
            ],
            xAxis: {
              categories: categories
            },
            yAxis: {
              title: { enabled: false, text: "Incidencias" }
            },
            plotOptions: {
                series: {
                    borderRadius: 5
                }
            },
          }}
        />
  )
}

export default BarChart