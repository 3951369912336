import React from "react";
import { withTranslation } from "react-i18next";
import "./styles.scss";
import PACPDIContainer from "../../organisms/PACPDIContainer";
import animationData from "../../../assets/media/icons/check-animation.json";
import Lottie from "react-lottie";
import { useHistory } from "react-router-dom";

const Success = (props) => {
  const { t, next } = props;

  const history = useHistory();

  const goBack = () => {
    const url = new URL(window.location.href);
    history.push("/" + url.searchParams.get("next"));
  };

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <PACPDIContainer backgroundSrc={"/assets/background-reuniones.webp"}>
      <div class="container">
        <div className="container__wrapper">
          <div class="animation-container">
            <Lottie options={defaultOptions} height={400} width={400} />
          </div>
          <h3>La reunión fue guardada con éxito</h3>
          <button onClick={goBack}>Volver</button>
        </div>
      </div>
    </PACPDIContainer>
  );
};

export default withTranslation()(Success);
