import React from "react";
import "./styles.scss";

const VideoCategory = (props) => {
  const { category } = props;
  return (
    <div className="videocategory">
      <h3>{category}</h3>
    </div>
  );
};

export default VideoCategory;
