import React, { useState } from "react";
import { Link } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import "./styles.scss";
import Paginacion from "../Paginacion";

const Table = ({
  cabeceras,
  setTablaFiltrada,
  tablaFiltrada,
  setPage,
  countTeams,
    page,
  pageLimit,
}) => {
  const [orden, setOrden] = useState(false);
  
  const ordenar = (props) => {
    if (orden) {
      setTablaFiltrada(
        tablaFiltrada.sort((a, b) =>
          a[props] > b[props] ? 1 : b[props] > a[props] ? -1 : 0
        )
      );
      setOrden(!orden);
    } else {
      console.log("sale")
      setTablaFiltrada(
        tablaFiltrada.sort((a, b) =>
          a[props] > b[props] ? -1 : b[props] > a[props] ? 1 : 0
        )
      );
      setOrden(!orden);
    }
  };

  return (
    <div className="total-table">
      <div className="mb-5">
        <h3>Listado de equipos</h3> 
      </div>
      <table className="table">
        <thead>
          <tr className="headers-table">
            {cabeceras.map((cabecera, j) => (
              <th className="table-th" key={j}>
                <div className="d-flex align-items-center">
                  <div className="me-5">{cabecera.label}</div>
                  {cabecera.field === "action" ? (
                    <div className="d-flex flex-column me-2 ">
                      <ArrowDropUpIcon
                        onClick={() => ordenar(cabecera.field)}
                        className="arrowIconUp d-none"
                      />
                      <ArrowDropDownIcon
                        onClick={() => ordenar(cabecera.field)}
                        className="arrowIconDown d-none"
                      />
                    </div>
                  ) : (
                    <div className="d-flex flex-column me-2 ">
                      <ArrowDropUpIcon
                        onClick={() => ordenar(cabecera.field)}
                        className="arrowIconUp"
                      />
                      <ArrowDropDownIcon
                        onClick={() => ordenar(cabecera.field)}
                        className="arrowIconDown"
                      />
                    </div>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
         {
           tablaFiltrada.slice(pageLimit * (page - 1), (pageLimit * (page - 1)) + pageLimit).map((elemento,i) =>{
              return(
                <tr key={i} className="elementos-tabla">
                  {cabeceras.map((cabecera, u) => {
                    return <td key={u} className="elementos">
                        {cabecera.field === "action" ? (
                          <Link
                            to={`/dashboard/equipos/${elemento.id}`}
                          >
                            <VisibilityIcon />
                          </Link>
                        ) : (
                          cabecera.field == 'code' ?
                            (
                              elemento['code']
                            ): (
                              elemento[cabecera.field]
                            )
                        )}
                      </td>
                  })}
            </tr>
              )
          })
         }
        </tbody>
      </table>
      <Paginacion setPage={setPage} countTeams={countTeams} />
    </div>
  );
};

export default Table;