import React from "react"
import MuiPagination from '@material-ui/lab/Pagination';
import useStyles from "./styles";
export function Pagination({
    pages,
    onChange,
    page
}) {
    const classes = useStyles();

    const handleChange = (event, value) => {
        onChange(value);
    };

    return (
        <div className={classes.root}>
            <MuiPagination count={pages}
                page={page}
                onChange={handleChange}
                variant="outlined"
                shape="rounded"
                className={classes.pagination}
            />
        </div>
    );
}

export default Pagination;