import { Grid } from "@material-ui/core";
import CardVideoItem from "components/atoms/CardVideoItem";
import CardVideoTitle from "components/atoms/CardVideoTitle";
import React from "react";

const CardVideo = ({ title, list }) => {
  return (
    <div className="mb-5  bg-white me-5 p-5 card-video">
      <CardVideoTitle title={title} />
      <Grid item container xs={12} spacing={2}>
        {list?.map((item, index) => (
          <CardVideoItem key={index} id={index + 1} label={item.tracked_item.name} />
        ))}
      </Grid>
    </div>
  );
};
export default CardVideo;
