import { BehaviorSubject } from "rxjs";

// import i18n from "i18n";

import Environment from "../environment";

import User from "../models/User";

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("currentUser"))
); // receive initial value, only when do next()

const getAuthToken = () => localStorage.getItem("auth_token");
const handleFetch = (type, url, variables, successFunc) => {
  const token = getAuthToken();

  const requestOptions = {
    method: type,
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": "es-ar",
      Token: token,
    },
    body: JSON.stringify(variables),
  };

  fetch(`${Environment.api}${url}`, requestOptions)
    .then((response) => {
      if (response.ok) {
        successFunc(response);
      }
    })
    .catch((error) => console.log(error));
};
export const AuthService = {
  expirationTime: 60 * 5, // Seconds
  idleInterval: null,
  idleTime: 0,
  currentUser: currentUserSubject.asObservable(),
  currentUserValue: () => {
    return currentUserSubject.value;
  },

  login: (email, password) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": "es-ar",
      },
      body: JSON.stringify({ email, password }),
    };

    return fetch(`${Environment.api}login`, requestOptions)
      .then((response) => {
        if (response.ok) {
          fetch(`${Environment.api}api/v2/token/`, requestOptions)
            .then((responseRest) => {
              if (responseRest.ok) {
                responseRest.json().then((dataRest) => {
                  localStorage.setItem("access_token", dataRest.access);
                  localStorage.setItem("refresh_token", dataRest.refresh);
                });
              }
              throw response;
            })
            .catch((error) => console.log(error));
          return response.json().then((data) => {
            localStorage.setItem("auth_token", data.token);
            localStorage.setItem("salesperson_id", data.user.salesperson_id);
            localStorage.setItem("user_id", data.user.id);
            localStorage.setItem("access", data.access_token);
            localStorage.setItem("refresh", data.expires_in);
            localStorage.setItem("first_name", data.user.first_name);
            localStorage.setItem("last_name", data.user.last_name);
            localStorage.setItem("salesperson_full_name", data.user.full_name);
            localStorage.setItem("salesperson_email", data.user.email);
            localStorage.setItem("intro_video_id", data.user.intro_video_id);
            localStorage.setItem("phone", data.user.phone);
            localStorage.setItem("team_id",data.user.team_id);
            localStorage.setItem("team_name",data.user.team_name);
            localStorage.setItem("team_type", data.user.team_type);
            localStorage.setItem("belongs_to_leaf",data.user.belongs_to_leaf);
            localStorage.setItem("belongs_to_root",data.user.belongs_to_root);
            return data;
          });
        }
        throw response;
      })
      .catch((error) => console.log(error));
  },

  user: () => {
    return {
      id: localStorage.getItem("user_id"),
      salesperson_id: localStorage.getItem("salesperson_id"),
      salesperson_first_name: localStorage.getItem("first_name"),
      salesperson_last_name: localStorage.getItem("last_name"),
      salesperson_full_name: localStorage.getItem("salesperson_full_name"),
      salesperson_email: localStorage.getItem("salesperson_email"),
      intro_video_id: localStorage.getItem("intro_video_id"),
      phone: localStorage.getItem("phone"),
      team_name: localStorage.getItem("team_name"),
      team_id: localStorage.getItem("team_id"),
      team_type: localStorage.getItem("team_type"),
      belongs_to_root: localStorage.getItem("belongs_to_root"),
      belongs_to_leaf: localStorage.getItem("belongs_to_leaf"),
      is: localStorage.getItem("is"),
    };
  },

  confirm: (token) => {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token: token }),
    };

    return fetch(`${Environment.api}api/auth/confirm/`, requestOptions).then(
      (response) => {
        if (response.ok) {
          return response.json().then((data) => {
            localStorage.setItem("message", data.message);
            localStorage.setItem("user", data.user);
            return data;
          });
        }
        throw response;
      }
    );
  },

  refresh: async () => {
    const refresh = localStorage.getItem("refresh");
    if (refresh) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ refresh: refresh }),
      };
      let response = await fetch(
        `${Environment.api}api/auth/refresh/`,
        requestOptions
      );
      if (response.status === 200) {
        let data = await response.json();
        localStorage.setItem("access", data.access_token);
        localStorage.setItem("refresh", data.expires_in);
      }
    }
  },

  logout: () => {
    localStorage.clear();
    currentUserSubject.next(null);
    AuthService.removeListeners();
  },

  loadUser: async (refresh = false) => {
    if (refresh) {
      await AuthService.refresh();
    }

    return new Promise((resolve, reject) => {
      const access = localStorage.getItem("access");
      if (!access) {
        reject("No Access");
        return;
      }

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + access,
        },
      };
      return fetch(`${Environment.api}api/auth/me/`, requestOptions)
        .then((response) => {
          if (response.ok) {
            response.json().then((data) => {
              if (!data.habilitado) {
                AuthService.logout();
                reject(response);
              } else {
                let user = new User(data);
                localStorage.setItem("currentUser", JSON.stringify(user));
                // localStorage.setItem('i18nextLng', user.language);
                // i18n.changeLanguage(user.language)
                currentUserSubject.next(user);
                AuthService.startListeners();
                resolve(user);
              }
            });
          } else {
            AuthService.logout();
            reject(response);
          }
        })
        .catch((error) => {
          AuthService.logout();
          reject(error);
        });
    });
  },

  getToken() {
    const token= localStorage.getItem("access");
    if(!token) return null;
    return `Bearer ${token}`;
  },

  getTokenRest() {
    const token= localStorage.getItem("access_token");
    if(!token) return null;
    return `Bearer ${token}`;
  },

  getId() {
    return localStorage.getItem("salesperson_id");
  },

  resetIdle: function() {
    const pendingDelta = AuthService.expirationTime - AuthService.idleTime;

    // If detects user activity and token is going to be expired refresh it
    if (/*pendingDelta > 0 &&*/ pendingDelta < 60) {
      AuthService.idleTime = 0;
      AuthService.refresh();
    }
  },

  timerIncrement: function() {
    AuthService.idleTime = AuthService.idleTime + 1;

    // Autologout when idleTime passes the expiration delta
    // if (AuthService.idleTime > AuthService.expirationTime) {
    //   AuthService.removeListeners();
    //   AuthService.logout();
    // }
  },

  removeListeners: () => {
    document.removeEventListener("keypress", AuthService.resetIdle, false);

    if (AuthService.idleInterval) clearInterval(AuthService.idleInterval);
  },

  startListeners: () => {
    AuthService.idleInterval = setInterval(AuthService.timerIncrement, 1000);
    document.addEventListener("mousemove", AuthService.resetIdle, false);
    document.addEventListener("keypress", AuthService.resetIdle, false);
  },

  isLogged: () => Boolean(getAuthToken()),
  post: (url, variables, successFunc) => {
    const token = getAuthToken();

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": "es-ar",
        Token: token,
      },
      body: JSON.stringify(variables),
    };

    fetch(`${Environment.api}${url}`, requestOptions)
      .then((response) => {
        if (response.ok) {
          successFunc(response);
        }
      })
      .catch((error) => console.log(error));
  },

  put: (url, variables, successFunc) => {
    handleFetch("PUT", url, variables, successFunc);
  },

  get: (url, successFunc) => {
    const token = getAuthToken();

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": "es-ar",
        Token: token,
      },
    };

    fetch(`${Environment.api}${url}`, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json().then((data) => {
            successFunc(response, data);
          });
        }
      })
      .catch((error) => console.log(error));
  },
};
