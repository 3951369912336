import React from "react";
import "./styles.scss";
import { InfoOutlined } from "@material-ui/icons";
import { getSrcVideoBlop } from "services/videos";
import { useModalVideo } from "contexts/modalVideo.context";
import { AuthService } from "services/AuthService";


const PACPDIHeader = ({ header, fullHeader, iconVideoId }) => {
  const { open: openVideoModal, setVideoSrc } = useModalVideo();
  const userId = AuthService.user().id;
  const handleOpenVideo = async () => {
    if (!iconVideoId) return;
    openVideoModal();
    const src = await getSrcVideoBlop( userId, iconVideoId);
    setVideoSrc(src,userId, iconVideoId);
  };

  return (
    <div className="pac-pdi__header d-flex w-100 align-items-center">
      <InfoOutlined
        style={{
          color: "#d4af37",
          height: 40,
          width: 40,
          marginRight: "1.5rem",
          cursor: "pointer",
        }}
        onClick={handleOpenVideo}
      />
      <h3>
        {header} - <span> {fullHeader} </span>{" "}
      </h3>
    </div>
  );
};

export default PACPDIHeader;
