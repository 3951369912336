// import React, { useEffect, useState } from 'react'
// import { Pagination } from 'react-bootstrap';
// import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';

// // let active = 2;

// const Paginacion = ({ pages , setPages }) => {
//     const [numbers , setNumbers ] = useState(pages)
//     const [active , setActive ] = useState(pages)
//     let items = [];
//     for (let number = 1; number <= 3; number++) {
        
//         items.push(
//           <Pagination.Item key={number} active={number === active} onClick={()=>handleChange(number)}>
//             {number}
//         </Pagination.Item>,
//       );
//     }
//     const onchangePagePrev = (number) => {
//       if(active > 1){
//         setActive(number-1)
//         setNumbers(number-1)
//         setPages(number-1)
//         console.log(number-1,"pagina");

//       }
//     }
    
//     const onchangePageNext = (number) => {
//       if(active < items.length){
//           setActive(number+1)
//           setNumbers(number+1)
//           setPages(number+1)
//         console.log(number+1,"pagina");

//       }
//     }
//     // console.log(active,"active");
//     console.log(active,"cuanto vale active al principio");
//     console.log(pages,"pages cuando llega");


//     const handleChange =(number) => {
//         setNumbers(number)
//         setActive(number)
//         setPages(number)
//         console.log(number);
//         console.log(pages,"pages cuando hace click");
//     }
    

//     useEffect(() => {
    
//       setActive(pages)
//     }, [pages])
    

//     return (
//       <div className='d-flex align-items-baseline'>
//          {/* <div>       
//           <ChevronLeftIcon value={items} onClick={()=>onchangePagePrev(numbers)}/>
//          <Pagination.Prev /> 

//          </div> */}
//         <div className='me-5'>
//           {`${10} Resultados de ${items.length}`}
//         </div>

//         <div>
        
//           <Pagination 
//           value={items}
//           >
//           <Pagination.Prev value={items} onClick={()=>onchangePagePrev(numbers)} />
//           {items}
//           <Pagination.Ellipsis />
//           <Pagination.Item 
//           value={items.length}
//           >{items.length}</Pagination.Item>
//           <Pagination.Next value={items} onClick={()=>onchangePageNext(numbers)}/>    
//           </Pagination>
          
         
//         </div>
//         {/* <div>
//           <ChevronRightIcon value={items} onClick={()=>onchangePageNext(numbers)}/>
//         </div>   */}

// {/* <Pagination>
 
//   <Pagination.Prev value={items} onClick={()=>onchangePagePrev(numbers)} />
//   <Pagination.Item>{1}</Pagination.Item>
//   <Pagination.Ellipsis />

//   <Pagination.Item>{numbers}</Pagination.Item>
//   <Pagination.Item>{11}</Pagination.Item>
//   <Pagination.Item active>{active}</Pagination.Item>
//   <Pagination.Item>{13}</Pagination.Item>
//   <Pagination.Item disabled>{14}</Pagination.Item>

//   <Pagination.Ellipsis />
//   <Pagination.Item>{items.length}</Pagination.Item>
//   <Pagination.Next value={items} onClick={()=>onchangePageNext(numbers)}/>
  
// </Pagination> */}
//       </div>
//   )
// }

// export default Paginacion



import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function Paginacion({setPage , countTeams}) {
  
  
  

  const handleChange = (event, value) => {
    
    setPage(value);
  };
  
  
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* <Pagination count={10} shape="rounded" /> */}
      <Pagination style={{ marginBottom: "15px" }} count={countTeams} variant="outlined" shape="rounded" onChange={handleChange}/>
    </div>
  );
}