import React, { useEffect, useState } from 'react'
import { DateRanges } from 'components/atoms/DateRange';
import SearchIcon from '@material-ui/icons/Search';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';

import "./styles.scss";

const Buscador = ({ 
  setTablaFiltrada,
  tablaFiltrada,
  teamsTabla,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  select,
  setSelect
}) => {
  const [input, setInput] = useState('');
  const history = useHistory();
 
  useEffect(() => {
    if(input !==""){
      const filter = teamsTabla.filter(el => el.code.toLowerCase().startsWith(input.toLowerCase()) )
      setTablaFiltrada(filter)
    }else{
      setTablaFiltrada(teamsTabla)
    }
  }, [input])

  useEffect(() => {
    if(select !==""){
      const filter = teamsTabla.filter((el) => {return el.level == select})
      setTablaFiltrada(filter)
    }else{
      setTablaFiltrada(teamsTabla)
    }
  }, [select])

  useEffect(() => {
    const filter = teamsTabla.filter((el) => {return el.level == select})
    console.log('teamsTabla', teamsTabla)
    setTablaFiltrada(filter)
  }, [teamsTabla])

  const [options, setOptions] = useState([]);
  useEffect(() => {
    console.log('tablaFiltrada', tablaFiltrada)
      if (tablaFiltrada && tablaFiltrada.length > 0) {
          setOptions(tablaFiltrada.map(function (unidad) {
              return { value: unidad.id, label: unidad.code }
          }));
      }
  }, [tablaFiltrada]);
  
  const handleChange = (option) => {
    console.log(option.value);
    setInput(option.value+'');
    history.push(`/dashboard/equipos/${option.value}`)
  }

  return (
    <div>
       <div className="contenedor-search d-flex justify-content-between  d-flex align-items-center">
            <div className="search">
              <SearchIcon className='icono-search'/>
              {/* <input
                  type="search"
                  // onChange={filter}
                  value={input}
                  onChange={(e)=>setInput(e.target.value)}
                  className="input"
                  placeholder="Buscar por codigo de equipo"
              /> */}
              <Select
                  options={options}
                  search
                  printOptions="open"
                  emptyMessage={() => <div className="select-search_emptymessage">No hay resultados</div>}
                  placeholder="Buscar por codigo de equipo"
                  onChange={handleChange}
                  value={input}
                  className="input"
              />
            </div>
            <div className="search">
              <h6 className=' pe-2 ps-1'>Nivel:</h6>
              <select id="mySelect" onChange={(e)=>setSelect(e.target.value)} className="search-nivel" placeholder='todos' value={select}>
                <option
                value={""}>
                  Nivel: Todos
                </option>
                {
                  teamsTabla.map((u) => { 
                        return {level: u.level, code: u.code}
                      }).filter((value, index, self) =>
                        index === self.findIndex((t) => (
                          t.level === value.level
                        ))
                      ).map((user)=>{
                      return ( 
                      <option 
                      value={user.level}
                      key={user.level}
                      
                      >
                        Nivel: {user.level}
                      </option>
                      )
                    })
                  }
              </select>
            </div>
            <DateRanges
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate} 
              setEndDate={setEndDate}
            />
        </div> 
    </div>
  )
}

export default Buscador