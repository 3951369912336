import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import useRequest from 'hooks/useRequest';

import { isMobile } from 'helpers/Mobile';

import Dashboard from 'components/templates/Dashboard';

const DashboardView = (props) => {
    const {
        beforeSubmit, afterSubmit, errors, message,
        showSuccess, setMessage, 
        dealWithError
    } = useRequest();

    const history = useHistory();

    return <Dashboard></Dashboard>
}

export default withTranslation()(DashboardView);