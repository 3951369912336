import React, { useState, useEffect, useRef } from "react";
import Environment from "environment";
import { withTranslation } from "react-i18next";
import "./styles.scss";
import { useHistory } from "react-router-dom";

import { AuthService } from "services/AuthService";
import Api from "services/ApiService";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import Layout from "components/organisms/Layout";

import TableAsesores from "components/molecules/TableAsesores";
import { DateRanges } from "components/atoms/DateRange";

import { useParams } from "react-router-dom";

import BarChart from "components/molecules/BarChart";
import PieChart from "components/molecules/ChartPie";
import { Redirect } from "react-router-dom";
import ListMeetSummary from "components/molecules/ListMeetSummary";
import Title from "components/molecules/Title";
import ListVideoTeca from "components/molecules/ListVideoTeca";

import { useQuery } from 'hooks/useQuery';
import { IFA } from "models/User";

function Equipo() {
  const history = useHistory();

  const [asesoresEquipo, setAsesoresEquipo] = useState([]);

  const { nroEquipo } = useParams();
  const [cantAsesores, setCantAsesores] = useState();
  const [idAsesores, setIdAsesores] = useState([]);
  const [idAsesor, setIdAsesor] = useState(false);
  const [user, setUser] = useState([])
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  //const userId = AuthService.user().id;
  const [userId, setUserId] = useState();
  const [pacsMetrics, setPacsMetrics] = useState(0);
  const [pdisMetrics, setPdisMetrics] = useState(0);
  const [ratioMetrics, setRatioMetrics] = useState(0);
  const text = "Por Asesor"
 
  const [selected, setSelected] = useState()

  const [pacs, setPacs] = useState([])
  const [pdis, setPdis] = useState([])
  const [categories, setCategories] = useState([])
  
  const [teamsPie, setTeamsPie] = useState([])
  const [metricsVideos, setMetricsVideos] = useState([]);

  const [whoseTotal, setWhoseTotal] = useState();

  const isLogged= localStorage.getItem("access");
  const loggedUser = useRef(AuthService.user());

  const query = useQuery();

  useEffect(() => {
    AuthService.get(`api/v2/teams/${nroEquipo}`, (r, data) => {
      setAsesoresEquipo(data);
      setCantAsesores(data.salesperson_count);
      setIdAsesores(data?.users?.map((elem) => {return elem.id}));
      console.log('data--',data?.users)
      console.log('users',data?.users)
      let dataMapped = data?.users ? data?.users.map((elem) => {
        return {
          name: elem.code,
          y: Math.round(elem.ratio),
          selected: elem.id == userId ? true : false,
          id: elem.id
        }
      }).filter((elem) => {return elem.y != 0}) : [];

      console.log('dataMapped-->', dataMapped)
      setTeamsPie(dataMapped)
      console.log(data.related_user.id)
      setUserId(data.related_user.id)
      setSelected(userId);
    });
/*
    AuthService.get(`api/v2/users/${userId}`, (r, data) => {
      setUser(data);
      setIdAsesor(data.salesperson_id)
    });*/
  }, []);

  useEffect(() => {
    if(userId){
      AuthService.get(`api/v2/users/${userId}`, (r, data) => {
        setUser(data);
        setIdAsesor(data.salesperson_id)
      });
    }
  }, [userId]);

  useEffect(() => {
    Api.fetch(`${Environment.api}api/v2/metrics/video/`, "GET")
      .then((data) => {
        setMetricsVideos(data);
      })
      .catch((error) => console.log(error));
  }, []);

  

    useEffect(() => {
      if(query.get('detalle') != 'true' ){
        console.log('metrics team')
          let queryParams = {
            ...(startDate && { from_date: startDate.toISOString().substring(0, 19) }),
            ...(endDate && { to_date: endDate.toISOString().substring(0, 19) }),
          };

          let restsParams = {
            ...(startDate && { from_date: startDate.toISOString().substring(0, 10) }),
            ...(endDate && { to_date: endDate.toISOString().substring(0, 10) }),
            ...(nroEquipo) && {team: nroEquipo},
          };

          let urlWithParams = Api.getUrlWithParams(`${Environment.api}metrics/interviews`, queryParams);
          
          //AuthService.get(urlWithParams.pathname.replace(/\//,'')+''+urlWithParams.search, (r, data) => setMetrics(data));

          Api.fetch(`${Environment.api}api/v2/metrics/teams/${nroEquipo}`, 'GET', restsParams )
            .then(data => {
              setPacs(data.pacs);
              setPdis(data.pdis);
              setCategories(data.categories);
              setWhoseTotal(data.code)
              let pacsMetrics = data.pacs.reduce((previousValue, currentValue) => previousValue + currentValue,
              0);

              let pdisMetrics = data.pdis.reduce((previousValue, currentValue) => previousValue + currentValue,
                  0);
              setPdisMetrics(pdisMetrics)
              setPacsMetrics(pacsMetrics)

              setRatioMetrics(Math.round(pdisMetrics * 100 / pacsMetrics))
            })
            .catch((error) => console.log(error));
      }
    }, [endDate, startDate]);  
      

    const handleClick = (selectedSalesperon) => {
      let restsParams = {
        ...(startDate) && {from_date: startDate.toISOString().substring(0,10)},
        ...(endDate) && {to_date: endDate.toISOString().substring(0,10)},
      };
      Api.fetch(`${Environment.api}api/v2/metrics/teams/${selectedSalesperon.id}`, 'GET', restsParams )
        .then(data => {
          setPacs(data.pacs);
          setPdis(data.pdis);
          setCategories(data.categories);
          setWhoseTotal(data.code)
          let pacsMetrics = data.pacs.reduce((previousValue, currentValue) => previousValue + currentValue,
          0);

          let pdisMetrics = data.pdis.reduce((previousValue, currentValue) => previousValue + currentValue,
              0);

          setPdisMetrics(pdisMetrics)
          setPacsMetrics(pacsMetrics)

          setRatioMetrics(Math.round(pdisMetrics * 100 / pacsMetrics))
        })
        .catch((error) => console.log(error));
    }

    useEffect(() => {
      console.log('idAsesor-->', idAsesor)
      if(query.get('detalle') == 'true' ){
      if(idAsesor){
          console.log(startDate && startDate.toISOString().substring(0, 10));
          let queryParams = {
            ...(startDate && { from_date: startDate.toISOString().substring(0, 19) }),
            ...(endDate && { to_date: endDate.toISOString().substring(0, 19) }),
          };

          let restsParams = {
            ...(startDate && { from_date: startDate.toISOString().substring(0, 10) }),
            ...(endDate && { to_date: endDate.toISOString().substring(0, 10) }),
          };

          let urlWithParams = Api.getUrlWithParams(
            `${Environment.api}metrics/interviews`,
            queryParams
          );

        console.log('salespersons team')

        Api.fetch(
          `${Environment.api}api/v2/metrics/salespersons/${idAsesor}`,
          "GET",
          restsParams
        )
          .then((data) => {
            console.log("Metricas asesor para id " + idAsesor);
            console.log(data);
            setPacs(data.pacs);
            setPdis(data.pdis);
            setCategories(data.categories);
            let pacsMetrics = data.pacs.reduce((previousValue, currentValue) => previousValue + currentValue,
            0);

            let pdisMetrics = data.pdis.reduce((previousValue, currentValue) => previousValue + currentValue,
                0);

            setPdisMetrics(pdisMetrics)
            setPacsMetrics(pacsMetrics)
            setRatioMetrics(Math.round(pdisMetrics * 100 / pacsMetrics))
          })
          .catch((error) => console.log(error));
        }
      }
  }, [idAsesor, endDate, startDate]);
 const chartComponent = useRef({});

  useEffect(() => {
    const chart = chartComponent.current?.chart;

    if (chart) chart.reflow(false);
  }, [loggedUser.current]);

  if(!isLogged) return <Redirect to="/"/>

  console.log(query.get('detalle'))

  return (
    <Layout backgroundColor="#dedede" style={{ marginLeft: 30 }}>
      <div className="">
        <div className="ms-4"></div>
        <div className="d-flex   graficos">
          <div className="mt-5">
            <div className="ms-4">
              <div>
                <h2 className="ms-3 title-equipo">
                  <ArrowBackIosIcon onClick={() => history.goBack()} />
                  {asesoresEquipo.code && (user.team == asesoresEquipo.id 
                    ? `Mi equipo - ${asesoresEquipo.code}`
                    : `Equipo - ${asesoresEquipo.code}`)}
                </h2>
                <div className="buscador-date1 ms-1">
                  <DateRanges
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                  />
                </div>
                <div className="ms-3">
                  <Title title={"Estadisticas de su equipo"} variant={"h3"} />
                </div>
              </div>
            </div>
            <div className="metricas ms-2">
              <ListMeetSummary pdisMetrics={pdisMetrics} pacsMetrics={pacsMetrics} ratioMetrics={ratioMetrics} />
            </div>
            <h3 className="d-flex mt-4 mb-4 ms-4">Incidencias por equipo</h3>
            <div className="highcharts ms-3">
                <BarChart title={whoseTotal} chartComponent={chartComponent} className="card-video" pacs={pacs} pdis={pdis} categories={categories}/>
                {(loggedUser.current.team_type !== IFA && query.get('detalle') != 'true' ) && <PieChart className="card-video" data={teamsPie} text={text} select={selected} setSelected={setSelected} handleClick={handleClick}/>}
            </div>
            <div className="lista-videos-asesor ms-3">
              <Title title={"Recursos"} />
              <div className="d-flex">
                <ListVideoTeca metricsVideos={metricsVideos}/>
              </div>
            </div>
          </div>
          <div className="list-videos ">
            {(loggedUser.current.team_type !== IFA && (query.get('detalle') != 'true' )) && <div className="tabla-asesores mt-5 ms-3">
              <h3>Asesores</h3>
              <TableAsesores
                cantAsesores={asesoresEquipo.users?.length}
                code={asesoresEquipo.code}
                id={asesoresEquipo.id}
                users={asesoresEquipo.users}
              />
            </div>}
          </div>
        </div>
      </div>
      <div className="separadorDashboard" />
      <div className="separadorDashboard" />
    </Layout>
  );
}
export default withTranslation()(Equipo);
