import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import './styles.scss';
import PACPDIContainer from '../../organisms/PACPDIContainer';
import PACPDIHeader from '../../atoms/PACPDIHeader';
import SubHeaderDetails from '../../atoms/SubHeadingDetails';
import {useHistory} from 'react-router-dom';
import {AuthService} from '../../../services/AuthService';
import ProfileIcon from "../../../assets/media/icons/profile.svg";
import Loading from 'components/atoms/Loading';

const Profile = (props) => {
    const {t} = props;


    const userId = localStorage.getItem('salesperson_id');
    const [userData, setUserData] = useState({});
    let videoComponent = useRef(null)
    let profileImageComponent = useRef(null)

    useEffect(() => {
        if (userId) {
            setUserData({
                email: localStorage.getItem('salesperson_email'),
                full_name: localStorage.getItem('salesperson_full_name'),
                phone: localStorage.getItem('phone'),
                intro_video_id: localStorage.getItem('intro_video_id'),
                salesperson_id: localStorage.getItem('salesperson_id'),
            })

        } else {
            const url = new URL(window.location.href);
            const id = url.searchParams.get('id')
            AuthService.get('users/salesperson/' + id, (r, data) => setUserData({
                full_name: data.full_name,
                phone: data.phone,
                email: data.phone,
                intro_video_id: data.intro_video_id,
                salesperson_id: data.salesperson_id,
            }))

        }
    }, [])

    useEffect(() => {
        getVideoPermisions(process.env.REACT_APP_API + "user_videos/" + String(userData.intro_video_id))
        getProfilePicture(`${process.env.REACT_APP_API}users/salesperson/${userData.salesperson_id}/picture`)
    }, [userData])

    async function copyPageUrl() {
        let url = userId ? window.location.href + '?id=' + userId : window.location.href;

        try {
            await navigator.clipboard.writeText(url);
            console.log('Page URL copied to clipboard');
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    }

    const getVideoPermisions = (url) => {
        fetch(url, {headers: {"Token": localStorage.getItem('auth_token')}})
            .then(response => response.blob())
            .then(blob => {
                if (videoComponent) {
                    videoComponent.current.src = URL.createObjectURL(blob)
                }
            });
    }

    const getProfilePicture = (url) => {
        fetch(url, {headers: {"Token": localStorage.getItem('auth_token')}})
            .then(response => response.blob())
            .then(blob => {
                if (blob.type.includes("image")) {
                    profileImageComponent.current.src = URL.createObjectURL(blob);
                } else {
                    profileImageComponent.current.src = ProfileIcon;
                }

            })
    }

    return (
        <PACPDIContainer hideNav={!userId} t={t}>
            {userData ?
                <div className="profile__container">
                    <div className="inner_container">
                        <video controls ref={videoComponent}/>
                    </div>
                    <div className="inner_container">
                        <img ref={profileImageComponent}/>
                        <div class="profile__content">
                            <h3>{userData.full_name}</h3>
                        </div>
                        <div class="profile__content">
                            <h3><a href={"mailto: " + userData.email}>{userData.email}</a></h3>
                        </div>
                        <div class="profile__content">
                            <h3><a href={"tel: " + userData.phone}>{userData.phone}</a></h3>
                        </div>
                        <div class="profile__content profile__share">
                            <h3 onClick={copyPageUrl}>Compartir perfil</h3>
                        </div>
                    </div>
                </div>
                : <Loading/>}
        </PACPDIContainer>
    )
}

export default withTranslation()(Profile);
