import React, { useEffect, useState } from "react";
import { CssBaseline, makeStyles } from "@material-ui/core";
import SideBar from "components/molecules/Sidebar";
import axios from "axios";

const useStyles = makeStyles({
  root: {
    display: "flex",
    minHeight: "100vh",
  },
  mainContent: ({ backgroundColor, backgroundImage, hiddenBefore }) => ({
    flex: 1,
    overflow: "auto",
    backgroundColor: backgroundColor? backgroundColor : "#000",

    "&:before": {
      backgroundImage: backgroundImage,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
      filter: !hiddenBefore ? "grayscale(0%)" : "grayscale(100%)",
      display: !hiddenBefore ? "none" : "block",
      content: '""',
      position: "absolute",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
      opacity: 0.2,
    },
  }),
  headerSeparator: {
    height: "48px",
  },
});

function Layout({
  children,
  backgroundColor = "#000",
  backgroundImageSrc = null,
  hiddeSideBar = false,
  ...props
}) {
  const backgroundImage = `url(${backgroundImageSrc})`;  
  const classes = useStyles({
    backgroundColor,
    backgroundImage,
    hiddenBefore: Boolean(backgroundImageSrc),
  });

  // const [perfil , setPerfil ] = useState();

  // useEffect(() => {
  //   alert("datos para el sidebar,nombre y equipo")
  //   axios.get('https://jsonplaceholder.typicode.com/users')
  //     .then(res => {
  //     setPerfil(res.data);
  //     })
  //     .catch(error => console.log(error));
  //   }, []);
  
  //   console.log(perfil,"datos perfil");

  return (
    <div className={classes.root}>
      <CssBaseline />
      {!hiddeSideBar && <SideBar />}
      <main className={classes.mainContent}>
        <div {...props}>{children}</div>
      </main>
    </div>
  );
}

export default Layout;
