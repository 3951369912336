import React from "react";
import CardMeetSummary from "../CardMeetSummary";
import Section from "../Section";

import "./styles.scss";

const ListMeetSummary = ({ pacsMetrics, pdisMetrics, ratioMetrics }) => {
    const summaryData = [
      {
        title: "PAC",
        description: "Promedio",
        quantity: pacsMetrics,
        backgroundColor: "#5b9bd5",
        prefix: "",
      },
      {
        title: "PDI",
        description: "Promedio",
        quantity: pdisMetrics,
        backgroundColor: "#a9d18e",
        prefix: "",
      },
      {
        title: "RATIO",
        description: "Promedio",
        quantity: ratioMetrics,
        backgroundColor: "#439e9a",
        prefix: "%",
      },
    ];
  
    return (
      <Section description={"Reuniones"}>
        {summaryData.map(
          ({ title, description, quantity, backgroundColor, prefix }) => (
            <CardMeetSummary
              key={title}
              title={title}
              description={description}
              quantity={quantity}
              backgroundColor={backgroundColor}
              fontSize="1rem"
              prefix={prefix}
            />
          )
        )}
      </Section>
    );
  };

  export default ListMeetSummary;