import React, { useState, useEffect, useRef } from "react";
import { withTranslation } from "react-i18next";
import "./styles.scss";
import PACPDIContainer from "../../organisms/PACPDIContainer";
import { useHistory } from "react-router-dom";
import Loading from "components/atoms/Loading";
import Pagination from "components/atoms/Pagination";
import { AuthService } from "services/AuthService";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Triangle from "../../../assets/media/icons/complete-interview.png";
import Eye from "../../../assets/media/icons/eye.png";

import { makeStyles } from "@material-ui/core";

const useStylesCircleStatus = makeStyles((theme) => ({
  circle: {
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "10px",
    marginLeft: "10px",
    color: "#fff",
    fontSize: "24px",
    fontWeight: "bold",
    cursor: "pointer",
    border: "2px solid #fff",
  },
}));

const CircleStatus = ({ statusNumber }) => {
  const classes = useStylesCircleStatus();
  return <div className={classes.circle}>{statusNumber}</div>;
};

const TableHead = () => {
  const columns = ["Última reunión", "Apellido", "Nombre", "Profesión", "Edad", "Acción"];
  return (
    <thead>
      <tr>
        {
          columns.map((column, index) => (
            <th key={index}>{column}</th>
          ))
        }
      </tr>
    </thead>
  );
}

const TableRowItem = ({ interview }) => {
  return (
    <tr>
      <td>{interview.created_at.split("T")[0]}</td>
      <td>{interview.client.last_name}</td>
      <td>{interview.client.first_name}</td>
      <td>{interview.client.occupation}</td>
      <td>{interview.client.age}</td>
      <td>
        {interview.is_complete ? (
          <Link to={"/pac/edit?id=" + interview.id}>
            <CircleStatus statusNumber={2} />
          </Link>
        ) : (
          <Link to={"/pdi?id=" + interview.id}>
            <CircleStatus statusNumber={1} />
          </Link>
        )}
      </td>
    </tr>
  );
}

const Meetings = (props) => {
  const { t } = props;

  const history = useHistory();
  const [interviews, setinterviews] = useState(null);
  const [page, setPage] = React.useState(1);
  const [pages, setPages] = React.useState(1);


  React.useEffect(() => {
    const MAX_INTERVIEWS_PER_PAGE = 10;
    const pagination = `qty=${MAX_INTERVIEWS_PER_PAGE}&page=${page}`;
    AuthService.get(`interviews?${pagination}`, (r, data) => {
      setPages(data?.pages)
      const interviewSortByDate = data.interviews.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      setinterviews(interviewSortByDate);
    });
  }, [page]);

  return (
    <div className="meetings">
      <PACPDIContainer backgroundSrc={"/assets/background-reuniones.webp"} t={t}>
        {!!interviews ? (
          <div class="meetings-container">
            <div className="header__container">
              <h1>{t("Reuniones")}</h1>
            </div>
            <div class="table-container">
              <table className="meetings-table">
                <TableHead />
                <tbody>
                  {interviews?.map((interview) => (
                    <TableRowItem interview={interview} />
                  ))}
                </tbody>
                <tfoot>
                  <Pagination
                    pages={pages}
                    page={page}
                    onChange={setPage}
                  />
                </tfoot>
              </table>
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </PACPDIContainer>
    </div>
  );
};

export default withTranslation()(Meetings);
