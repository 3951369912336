const baseUrl = process.env.REACT_APP_API;

export const getSrcVideoBlop = async (user_id, id) => {
  const token = localStorage.getItem("auth_token");
  const headers = { Token: token };
  const url = await fetch(`${baseUrl}videos/${user_id}/info/${id}`, {
    headers: token ? headers : {},
  })
    .then((resp) => resp.json());
  return baseUrl + url?.video?.source;
  
};
