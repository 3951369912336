import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import "./styles.scss";
import CountUp from "react-countup";
import { AuthService } from "services/AuthService";
//refactor
import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { CastSharp, ShareSharp } from "@material-ui/icons";
import Layout from "components/organisms/Layout";
import cn from "classnames";
import IconMeet from "assets/media/icons/icon-meet.png";
import IconVideo from "assets/media/icons/icon-video.png";
import { rem } from "polished";
import { Redirect } from "react-router-dom";
import Api from "services/ApiService";
import Environment from "environment";

const useStylesCardSummary = makeStyles(() => ({
  root: ({ backgroundColor }) => ({
    backgroundColor: backgroundColor,
    padding: 20,
    paddingRight: 40,
    paddingLeft: 40,
    borderRadius: 30,
    width: "30%",
    margin: 10,
  }),
  countUp: {
    fontSize: "3.2em",
  },
  textColor: {
    color: "white",
  },
  bold: {
    fontWeight: "bold",
  },
}));

const animationDuration = 1.5;

const CardMeetSummary = ({
  title,
  description,
  quantity,
  backgroundColor,
  prefix,
}) => {
  const classes = useStylesCardSummary({
    backgroundColor,
  });
  return (
    <Paper elevation={6} className={"dashboard " + classes.root}>
      <Grid container>
        <Grid item xs={7}>
          <Grid item xs={12} container alignItems="center">
            <Typography
              className={cn(classes.textColor, classes.bold)}
              variant="h3"
              style={{fontSize: "2.5rem"}}
            >
              {String(title).toUpperCase()}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.textColor} variant="subtitle1">
              {description}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={5} container justifyContent="flex-end" alignItems="center">
          <CountUp
            className={cn(classes.countUp, classes.textColor, classes.bold)}
            start={0}
            end={quantity}
            duration={animationDuration}
            useEasing={true}
            useGrouping={true}
            decimals={0}
            suffix={prefix}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

const ListMeetSummary = ({ metrics }) => {
  const summaryData = [
    {
      title: "Pac",
      description: "Promedio semanal",
      quantity: metrics?.created_interviews,
      backgroundColor: "#5b9bd5",
      prefix: "",
    },
    {
      title: "PDI",
      description: "Promedio semanal",
      quantity: metrics?.completed_interviews,
      backgroundColor: "#a9d18e",
      prefix: "",

    },
    {
      title: "Ratio",
      description: "de efectividad",
      quantity: metrics?.effectiveness,
      backgroundColor: "#439e9a",
      prefix: "%",
    },
  ];

  return (
    <Section Icon={IconMeet} description={"Reuniones"}>
      {summaryData.map(
        ({ title, description, quantity, backgroundColor, prefix }) => (
          <CardMeetSummary
            key={title}
            title={title}
            description={description}
            quantity={quantity}
            backgroundColor={backgroundColor}
            fontSize="2.5rem"
            prefix={prefix}
          />
        )
      )}
    </Section>
  );
};

const useStyleCardVideoItem = makeStyles(() => ({
  text: {
    color: "#a3a3a3",
    fontWeight: "normal",
    letterSpacing: "3px",
  },
}));

const CardVideoItem = ({ id, label }) => {
  const classes = useStyleCardVideoItem();
  return (
    <>
      <Grid item xs={12}>
        <span className={classes.text}>{id}. {label}</span>
      </Grid>
    </>
  );
};

const useStyleCardVideoTitle = makeStyles(() => ({
  root: {
    //height: 50,
    textAlign: "center",
    marginBottom: rem("30px"),
  },
  title: {
    fontWeight: "bold",
    color: "#272727",
    letterSpacing: "3px",
    marginLeft: rem("10px"),
  },
}));
const CardVideoTitle = ({ title, Icon }) => {
  const classes = useStyleCardVideoTitle();
  return (
    <>
      <Grid container className={classes.root} alignItems="center">
        <Grid item xs={1}>
          <Icon
            style={{
              color: "#2e6ca4",
              fontSize: "30px",
            }}
          />
        </Grid>
        <Grid item xs={11} container justifyContent="flex-start">
          <Typography className={classes.title} variant="h6">
            {title}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

const useStyleCardVideo = makeStyles(() => ({
  root: {
    backgroundColor: "white",
    padding: 40,
    borderRadius: 50,
    width: "45%",
    margin: 10,
    paddingTop: 30,
  },
}));

const CardVideo = ({ title, list, Icon }) => {
  const classes = useStyleCardVideo();
  return (
    <Paper elevation={6} className={classes.root}>
      <Grid container>
        <CardVideoTitle title={title} Icon={Icon} />

        <Grid item container xs={12} spacing={2}>
          {list?.map((item, index) => (
            <CardVideoItem key={index} id={index + 1} label={item.tracked_item.name} />
          ))}
        </Grid>
      </Grid>
    </Paper>
  );
};

const ListVideoTeca = ({metricsVideos}) => {
  const data = [
    {
      title: "Videos más utilizados",
      Icon: CastSharp,
      list: metricsVideos?.viewed,
    },
    {
      title: "Videos más compartidos",
      Icon: ShareSharp,
      list: metricsVideos?.shared,
    },
  ];
  return (
    <Section Icon={IconVideo} description="VideoTeca">
      {data?.map(({ title, Icon, list }, index) => (
        <CardVideo key={index} title={title} list={list} Icon={Icon} />
      ))}
    </Section>
  );
};

const IconSection = ({ src, description }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid container style={{ width: 150, padding: 20 }}>
        <Grid item xs={12}>
          <img src={src} alt="icon-meet" style={{ height: "80px" }} />
        </Grid>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            variant="h6"
            style={{ color: "#878787", fontWeight: "normal" }}
          >
            {String(description).toUpperCase()}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyleSection = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "space-evenly",
  },
}));

const Section = ({ children, Icon, description }) => {
  const classes = useStyleSection();
  return (
    <Grid container>
      <Grid item xs={2} container alignItems="center" justifyContent="center">
        <IconSection src={Icon} description={description} />
      </Grid>
      <Grid item xs={10} container justifyContent="space-evenly">
        {children}
      </Grid>
    </Grid>
  );
};

const Title = () => {
  const title = "Performance en los últimos 30 días:";
  return (
    <Typography
      variant="h6"
      style={{
        color: "#272727",
        fontWeight: "normal",
        letterSpacing: "3px",
        paddingTop: 50,
        paddingBottom: 50,
        fontSize: "1.5rem",
        marginLeft: "2rem",
      }}
    >
      {title}
    </Typography>
  );
};

function Dashboard() {
  const [metrics, setMetrics] = React.useState(null);
  const [metricsVideos, setMetricsVideos] = useState([]);
  
  useEffect(() => {
    AuthService.get("metrics/interviews_home", (r, data) => setMetrics(data));
  }, []);

  useEffect(() => {
    Api.fetch(`${Environment.api}api/v2/metrics/video/`, "GET")
      .then((data) => {
        setMetricsVideos(data);
      })
      .catch((error) => console.log(error));
  }, []);

  const isLogged= localStorage.getItem("access");

  
  
  if(!isLogged) return <Redirect to="/"/>
  
  return (
    <Layout backgroundColor="#dedede" style={{ marginLeft: 30 }}>
      <Title />
      <ListMeetSummary metrics={metrics} />
      <div className="separadorDashboard" />
      <ListVideoTeca metricsVideos={metricsVideos}/>
    </Layout>
  );
}

export default withTranslation()(Dashboard);
