import { CastSharp, ShareSharp } from "@material-ui/icons";
import React from "react";
import CardVideo from "../CardVideo";


const ListVideoTeca = ({metricsVideos}) => {
    const data = [
      {
        title: "Videos más utilizados",
        Icon: CastSharp,
        list: metricsVideos?.viewed,
      },
      {
        title: "Videos más compartidos",
        Icon: ShareSharp,
        list: metricsVideos?.shared,
      },
    ];
    return (
      <>
        {data?.map(({ title, Icon, list }, index) => (
          <CardVideo key={index} title={title} list={list} />
        ))}
      </>
    );
  };
  export default ListVideoTeca;