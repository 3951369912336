import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import useRequest from 'hooks/useRequest';

import { isMobile } from 'helpers/Mobile';

import Profile from 'components/templates/Profile';

const ProfileView = (props) => {
    const {
        beforeSubmit, afterSubmit, errors, message,
        showSuccess, setMessage, 
        dealWithError
    } = useRequest();

    const history = useHistory();

    return <Profile></Profile>
}

export default withTranslation()(ProfileView);