import React from "react";

import { Grid } from "@material-ui/core";

const Section = ({ children }) => {
    return (
      <Grid container>
        <Grid item xs={11} container justifyContent="space-evenly">
          {children}
        </Grid>
      </Grid>
    );
  };

  export default Section;