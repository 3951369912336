import React from "react";
import { withTranslation } from "react-i18next";
import "./styles.scss";
import VideoCategoryContainer from "../../molecules/videoCategoryContainer";
import PACPDIContainer from "../../organisms/PACPDIContainer";

const Videoteca = (props) => {
  const { t } = props;

  return (
    <div className="videoteca">
    <PACPDIContainer
      t={t}
      hideNav={false}
      backgroundSrc={"/assets/" + props.background}
    >
        <div className="videoteca__container">
          <div className="videoteca__header-container">
            <h3>{t(props.title)}</h3>
          </div>
          <div className="videoteca__videos-container">
            <VideoCategoryContainer type={props.type} />
          </div>
        </div>
      </PACPDIContainer>
    </div>
  );
};

export default withTranslation()(Videoteca);
