import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import "./styles.scss";
import PACPDIContainer from "../../organisms/PACPDIContainer";
import PACPDIHeader from "../../atoms/PACPDIHeader";
import SubHeaderDetails from "../../atoms/SubHeadingDetails";
import PDITable from "../../atoms/PDITable";
import { useHistory } from "react-router-dom";
import { AuthService } from "services/AuthService";
import Loading from "components/atoms/Loading";
import { makeStyles } from "@material-ui/core";
import { useInterviewTemp } from "contexts/interviewTemp.context";

const convertInvestmentFoundToObj = (arr = []) => {
  let obj = {};
  if (!arr.length) return obj;
  arr.forEach((item) => {
    obj[item.code] = item.value;
  });
  return obj;
};

const convertObjInvestmentFoundToArr = (obj = {}) => {
  let arr = [];
  if (!Object.keys(obj).length) return arr;
  Object.keys(obj).forEach((key) => {
    arr.push({ code: key, value: obj[key] });
  });
  return arr;
}

const PDI = (props) => {
  const { t } = props;

  const history = useHistory();
  const { stateTmp, setStateTmp, setResumeInterViewUrl } = useInterviewTemp();
  const [totalValue, setTotalValue] = useState(0);
  const [percentageData, setPercentageData] = useState({});
  const [values, setValues] = React.useState({})
  const url = new URL(window.location.href);
  const interviewId = url.searchParams.get("id");
  const isCompleted = url.searchParams.get("is_complete");
  const isEdit = Boolean(interviewId);
  const keySaveTmp = isEdit ? `update_pdi_${interviewId}` : "save_pdi";
  const tmpValues = { ...stateTmp?.[keySaveTmp] };
  const { investment_funds, ...allTmpValues } = tmpValues;

  const investment_funds_arr = convertObjInvestmentFoundToArr(investment_funds);
  const [interviewData, setInterviewData] = useState({
    ...allTmpValues,
    investment_funds: investment_funds_arr
  });



  useEffect(() => {
    const { pathname, search } = history?.location || {};
    if (isEdit) {
      setResumeInterViewUrl(`${pathname}${search}`);
    }

    AuthService.get("interviews/" + interviewId, (r, data) => {
      const isEmptyInvestmentFundsArr = !investment_funds_arr?.length
      const investment_funds = isEmptyInvestmentFundsArr ? data?.investment_funds : investment_funds_arr
      setInterviewData({
        ...data,
        ...allTmpValues,
        investment_funds

      })
    });
  }, []);

  const handleSubmit = () => {
    const tmpForm = {
      [keySaveTmp]: {},
    };
    //reset tmp
    setStateTmp(tmpForm)
    setResumeInterViewUrl(null);

    let form = document.getElementById("pac-pdi-form");

    form.reportValidity();

    if (form.checkValidity()) {
      AuthService.put(
        "interviews/" + interviewId,
        {
          is_complete: true,
          investment_funds: percentageData,
          ...values
        },
        () => {
          history.push({
            pathname: "/success",
            search: "?next=reuniones",
          });
        }
      );
    }
  };





  const handleChange = (name, value) => {
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };


  React.useEffect(() => {
    const tmpForm = {
      [keySaveTmp]: { ...values, investment_funds: percentageData },
    };
    setStateTmp(tmpForm);
  }, [values, percentageData]);

  return (
    <PACPDIContainer
      updateId={isCompleted ? null : interviewId}
      t={t}
      submit={handleSubmit}
      hideGoBackPid={true}
      hideGoBackPac={false}
      backgroundSrc="/assets/background-pid.webp"
    >
      {interviewData ? (
        <>
          <PACPDIHeader
            header={t("PDI")}
            fullHeader={t("Propuesta de inversión")}
            iconVideoId={40}
          />
          <SubHeaderDetails
            initialData={interviewData.client}
            disabled
            onChange={(value) => handleChange("client", value)}
            isEdit={isEdit}
          />
          <div className="pdi-table-comp__container d-flex w-100 justify-content-center">
            <PDITable
              isCompleted={interviewData?.is_complete}
              investment_funds={
                convertInvestmentFoundToObj(interviewData?.investment_funds) ||
                {}
              }
              percentage={{
                percentage: percentageData,
                setPercentage: setPercentageData,
              }}
              verify={(total) => setTotalValue(total)}
            />
          </div>
        </>
      ) : (
        <Loading />
      )}
    </PACPDIContainer>
  );
};

export default withTranslation()(PDI);
