import React from "react";
import { withTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import useRequest from "hooks/useRequest";

import { isMobile } from "helpers/Mobile";

import VideoInfo from "components/templates/videoInfo";

function videoInfoView(props) {
  const { user_id } = props.match.params;
  const { id } = props.match.params;
  return <VideoInfo user_id={user_id} id={id} />;
}

export default withTranslation()(videoInfoView);
