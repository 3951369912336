import React from "react";
import "./styles.scss";

const Footer = (props) => {
  return (
    <div className="footer__container">
      {props.children}
      <h4>
        - All rights reserved IBP group llc USA - for internal use only -
      </h4>
    </div>
  );
};

export default Footer;
