import React from "react";
import "./styles.scss";
import { isMobile } from "../../../helpers/Mobile";
import { rem } from "polished";

const VideoButton = (props) => {
  const { label, handleOpenPopUp } = props;

  const handleClickButton = async (e) => {
    e.preventDefault();
    handleOpenPopUp();
  };

  return (
    <button onClick={handleClickButton} className="video-button">
      {label}
    </button>
  );
};

export default VideoButton;
