import React, { useState, useEffect } from "react";
import Environment from "environment";
import { withTranslation } from "react-i18next";
import "./styles.scss";
import { useHistory } from "react-router-dom";
import { AuthService } from "services/AuthService";
import Api from "services/ApiService";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import Layout from "components/organisms/Layout";
import { DateRanges } from "components/atoms/DateRange";
import { useParams } from "react-router-dom";
import BarChart from "components/molecules/BarChart";
import { Redirect } from "react-router-dom";
import ListVideoTeca from "components/molecules/ListVideoTeca";
import Title from "components/molecules/Title";
import ListMeetSummary from "components/molecules/ListMeetSummary";

function Asesor() {
  const history = useHistory();
  const { idAsesor, idUser } = useParams();
  const userName = AuthService.user().salesperson_first_name;
  const lastName = AuthService.user().salesperson_last_name;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [datosAsesores, setDatosAsesores] = useState([]);
  const [pacs, setPacs] = useState([])
  const [pdis, setPdis] = useState([])
  const [categories, setCategories] = useState([])
  const [metricsVideos, setMetricsVideos] = useState([]);

  const [pacsMetrics, setPacsMetrics] = useState(0);
  const [pdisMetrics, setPdisMetrics] = useState(0);
  const [ratioMetrics, setRatioMetrics] = useState(0);
  
  useEffect(() => {
    Api.fetch(`${Environment.api}api/v2/users/${idUser}`, 'GET' )
      .then(data => {
        console.log('Data asesor '+idUser);
        console.log(data);
        setDatosAsesores(data);
      })
      .catch((error) => console.log(error));
  }, [idUser]);

  useEffect(() => {
    Api.fetch(`${Environment.api}api/v2/metrics/video/`, "GET")
      .then((data) => {
        setMetricsVideos(data);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    console.log(startDate && startDate.toISOString().substring(0, 10));
    let queryParams = {
      ...(startDate && { from_date: startDate.toISOString().substring(0, 19) }),
      ...(endDate && { to_date: endDate.toISOString().substring(0, 19) }),
    };

    let restsParams = {
      ...(startDate && { from_date: startDate.toISOString().substring(0, 10) }),
      ...(endDate && { to_date: endDate.toISOString().substring(0, 10) }),
    };

    let urlWithParams = Api.getUrlWithParams(
      `${Environment.api}metrics/interviews`,
      queryParams
    );

    /* AuthService.get(
      urlWithParams.pathname.replace(/\//, "") + "" + urlWithParams.search,
      (r, data) => setMetrics(data)
    ); */

    Api.fetch(
      `${Environment.api}api/v2/metrics/salespersons/${idAsesor}`,
      "GET",
      restsParams
    )
      .then((data) => {
        console.log("Metricas asesor para id " + idAsesor);
        console.log(data);
        setPacs(data.pacs);
        setPdis(data.pdis);
        setCategories(data.categories);
        let pacsMetrics = data.pacs.reduce((previousValue, currentValue) => previousValue + currentValue,
        0);

        let pdisMetrics = data.pdis.reduce((previousValue, currentValue) => previousValue + currentValue,
            0);

        setPdisMetrics(pdisMetrics)
        setPacsMetrics(pacsMetrics)
        setRatioMetrics(Math.round(pdisMetrics * 100 / pacsMetrics))
      })
      .catch((error) => console.log(error));
  }, [endDate, startDate]);

  const isLogged = localStorage.getItem("access");

  if (!isLogged) return <Redirect to="/" />;

  return (
    <Layout backgroundColor="#dedede" style={{ marginLeft: 30 }}>
      <div className="">
        <div className="d-flex   graficos-asesor">
          <div className="mt-5 ">
            <div className="ms-4">
              <div>
                <div className="d-flex align-items-center">
                  <h2 className="nombre-asesor">
                    <ArrowBackIosIcon  onClick={() => history.goBack()} />
                    {datosAsesores.full_name}
                  </h2>
                  <h6 className="ms-5 row asesor-titulo">Asesor</h6>
                </div>
                <div className="buscador-date">
                  <DateRanges
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                  />
                </div>
                <div className="ms-3">
                  <Title title={"Estadisticas del equipo"} variant={"h3"} />
                </div>
              </div>
            </div>
            <div className="recursos ms-3">
              <ListMeetSummary pdisMetrics={pdisMetrics} pacsMetrics={pacsMetrics} ratioMetrics={ratioMetrics} />
            </div>
          </div>
        </div>
        <div className="graficos2 mt-5 ms-3">
          <h3 className="d-flex  mb-5 ms-4">Incidencias del asesor</h3>
          <div className="highcharts">
            <BarChart
              className="card-video"
              pacs={pacs}
              pdis={pdis}
              categories={categories}
            />
          </div>
        </div>
        <div className="lista-videos-asesor ms-3">
          <Title title={"Recursos"} />
          <div className="d-flex">
            <ListVideoTeca metricsVideos={metricsVideos}/>
          </div>
        </div>
      </div>
      <div className="separadorDashboard" />
      <div className="separadorDashboard" />
    </Layout>
  );
}
export default withTranslation()(Asesor);
