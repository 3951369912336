import { Typography } from "@material-ui/core";
import React from "react";


const Title = ({ title, variant }) => {
    return (
      <Typography
        variant={variant}
        style={{
          color: "#272727",
          fontWeight: "bold",
          letterSpacing: "3px",
          paddingTop: 50,
          paddingBottom: 30,
          fontSize: "1.5rem",
        }}
      >
        {title}
      </Typography>
    );
  };

  export default Title;