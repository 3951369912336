import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import useRequest from 'hooks/useRequest';

import { isMobile } from 'helpers/Mobile';

import Videoteca from 'components/templates/Videoteca';

const VideoView = (props) => {
    const {
        beforeSubmit, afterSubmit, errors, message,
        showSuccess, setMessage, 
        dealWithError
    } = useRequest();

    const history = useHistory();

    return <Videoteca type="library" background="background-videoteca.webp" title="Videoteca Comercial"></Videoteca>
}

export default withTranslation()(VideoView);