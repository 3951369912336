import React from 'react'
import './styles.scss';

const Modal = (props) => {
    const {isOpen, children, close} = props;

    return <div className={isOpen ? "modal__container modal__container--active" : "modal__container"} onClick={close}>
        <div onClick={ e => e.stopPropagation()}>
            {children}
        </div>
    </div>
}

export default Modal;